import Axios from 'axios';

const config = () => {
  const ID_APP = 1;
  const baseURL = 'https://ricalapis.ricaldone.edu.sv/';
  const api = baseURL + 'core_api/public/';
  const img = baseURL + 'core_api/public/resources/images/';
  return {
    api: api,
    images: img,
    setApiToken: (token) => {
      sessionStorage.setItem('core_token', token);
    },
    getApiToken: () => {
      return sessionStorage.getItem('core_token');
    },
    toFormData: (obj) => {
      var form_data = new FormData();
      for (var key in obj) {
        form_data.append(key, obj[key]);
      }
      return form_data;
    },
    searchArr: (data, search) => {
      let results = data.find((item) => item.action === search);
      return results;
    },
    getUserOnline: async (callBack) => {
      let token = sessionStorage.getItem('core_token');
      Axios.defaults.headers['Authorization'] = token;
      await Axios.get(api + 'profile/my_data').then(({ data }) => {
        if (data.response === false) {
          callBack([]);
        } else {
          callBack(data);
        }
      });
    },
    getTypeUsers: async (callBack) => {
      let token = sessionStorage.getItem('core_token');
      Axios.defaults.headers['Authorization'] = token;
      await Axios.get(api + 'access/user_type').then(({ data }) => {
        if (data.response === false) {
          callBack([]);
        } else {
          let dataApp = data.filter((item) => item.id_application === ID_APP);
          callBack(dataApp);
        }
      });
    },
    getPermissions: async (callBack) => {
      let token = sessionStorage.getItem('core_token');
      Axios.defaults.headers['Authorization'] = token;
      await Axios.get(api + 'access/permission').then(({ data }) => {
        if (data.response === false) {
          callBack([]);
        } else {
          let dataApp = data.filter((item) => item.id_application === ID_APP);
          callBack(dataApp);
        }
      });
    },
    getActionsPermissions: async (callBack) => {
      let token = sessionStorage.getItem('core_token');
      Axios.defaults.headers['Authorization'] = token;
      await Axios.get(api + 'access/action_permission').then(({ data }) => {
        if (data.response === false) {
          callBack([]);
        } else {
          let dataApp = data.filter((item) => item.id_application === ID_APP);
          callBack(dataApp);
        }
      });
    },
  };
};

export default config;
