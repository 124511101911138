import React from 'react';
import { Box, Container, Typography } from '@material-ui/core';
import { Link, Link as RouterLink } from 'react-router-dom';

const NotFoundContainer = () => {
  return (
    <Box
      alignItems="center"
      display="flex"
      flexDirection="column"
      height="100vh"
      width="100%"
      justifyContent="center"
    >
      <Container maxWidth="sm">
        <Typography align="center" color="textPrimary" variant="h4">
          ERROR 404: La página que estás buscando no existe
        </Typography>
        <Typography align="center" color="textPrimary" variant="subtitle2">
          Asegurate de haber ingresado la ruta correctamente o utiliza la navegación para moverte entre las diferentes pantallas del sistema.
        </Typography>
        <Box mt={4}>
          <Typography align="center">
            <Link component={RouterLink} to="/core">
              Ir al inicio
            </Link>
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default NotFoundContainer;
