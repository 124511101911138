import { makeStyles } from '@material-ui/core';
import { orange, red, grey } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  chips: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    margin: 0,
  },
  dark: {
    color: grey[900],
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  waring: {
    color: orange[500],
  },
  danger: {
    color: red[500],
  },
}));

export default useStyles;
