import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  title: {
    flexGrow: 1,
  },
  logoBrand: {
    width: 200,
    marginTop: 10,
    [theme.breakpoints.down('md')]: {
      width: 175,
    },
  },
  mrDos: {
    marginRight: '.3rem',
  },
}));

export default useStyles;
