import React from 'react';
import { Outlet } from 'react-router';

import style from './style';

const MainLayout = () => {
  const classes = style();

  return (
    <div className={classes.container}>
      <Outlet />
    </div>
  );
};

export default MainLayout;
