import React from 'react';
import PropTypes from 'prop-types';
import MUIDataTable from 'mui-datatables';

const language = {
  body: {
    noMatch: 'Sorry, no matching records found',
    toolTip: 'Sort',
    columnHeaderTooltip: (column) => `Sort for ${column.label}`,
  },
  pagination: {
    next: 'Siguiente',
    previous: 'Anterior',
    rowsPerPage: 'Filas por página:',
    displayRows: 'de',
  },
  toolbar: {
    search: 'Buscar',
    downloadCsv: 'Descargar CSV',
    print: 'Imprimir',
    viewColumns: 'Ver columnas',
    filterTable: 'Filtrar tabla',
  },
  filter: {
    all: 'Todo',
    title: 'Filtro',
    reset: 'Restablecer',
  },
  viewColumns: {
    title: 'Columnas',
    titleAria: 'Ver/Ocultar columnas',
  },
  selectedRows: {
    text: 'fila(s) seleccionada',
    delete: 'Eliminar',
    deleteAria: 'Eliminar fila(s) seleccionada(s)',
  },
};

const DataTable = ({ title, data, columns, options }) => {
  //forzando datatable en spanish
  options = { ...options, textLabels: language };
  return (
    <MUIDataTable
      title={title}
      data={data}
      columns={columns}
      options={options}
    ></MUIDataTable>
  );
};

DataTable.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  options: PropTypes.object,
};

DataTable.defaultProps = {
  options: {
    selectableRows: 'none',
    print: false,
    fixedHeader: true,
  },
};

export default DataTable;
