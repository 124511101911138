import React, { useEffect, useState } from 'react';
import { NavLink as RouterLink, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import Axios from 'axios';
import {
  Dialog,
  Button,
  DialogContent,
  DialogActions,
  Tooltip,
  IconButton,
} from '@material-ui/core/';
import Edit from '@material-ui/icons/Edit';
import Delete from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import SettingsIcon from '@material-ui/icons/Settings';
import DataTable from '../../../components/DataTable/DataTable';
import BackgroundLoad from '../../../components/BackgroundLoad';
import ConfirmDialog from '../../../components/ConfirmDialog';
import Toast from '../../../components/Toast';
import config from '../../../config';
import style from './style';

const PermissionsList = (props) => {
  const classes = style();
  const params = useParams();
  const id_application = params.id_application;
  //const
  const { actionsOnly } = props;
  const { api, getApiToken, searchArr } = config();
  Axios.defaults.headers['Authorization'] = getApiToken();
  const [toast, setToast] = useState({ isOpen: false, message: '', type: '' });
  const [loading, setLoading] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState({ isOpen: false });
  const [open, setOpen] = useState(false);
  const [mount, setMount] = useState(0);
  //data
  const [permissions, setPermissions] = useState([{ id_permission: 0 }]);
  const [actionsPermissions, setActionsPermissions] = useState([]);
  const [actions, setActions] = useState([]);
  const [permissionsSelected, setPermissionsSelected] = useState({
    id_permission: 0,
    actions: [],
    actionsIndex: [],
  });
  //metodos
  const handleClickOpen = () => {
    setOpen(true);
  };
  const clickClose = () => {
    setOpen(false);
  };
  //peticiones
  const savePermissionAction = () => {
    Axios.post(
      api + 'action_permission/' + permissionsSelected.id_permission,
      permissionsSelected
    ).then(({ data }) => {
      setToast({
        isOpen: true,
        message: data.message,
        type: data.response ? 'success' : 'warning',
      });
      if (data.response) {
        clickClose();
        setMount(mount + 1);
      }
    });
  };
  const clickDelete = (index, row) => {
    setConfirmDialog({
      isOpen: true,
      title: 'Advertencia',
      message: '¿Desea eliminar el registro seleccionado?',
      clickConfirm: () => {
        setLoading(true);
        Axios.delete(api + 'permission/' + permissions[index].id_permission)
          .then(({ data }) => {
            setToast({
              isOpen: true,
              message: data.message,
              type: data.response ? 'success' : 'warning',
            });
            if (data.response) {
              setMount(mount + 1);
            }
          })
          .then(() => setLoading(false));
      },
    });
  };
  //table permissions
  const columns = [
    {
      name: 'permission',
      label: 'Permiso',
    },
    {
      name: 'actionsPermissions',
      label: 'Acciones del permiso',
      options: {
        customBodyRenderLite: (dataIndex, rowIndex) => {
          let actionsSelected = [];
          actionsPermissions.forEach((action) => {
            if (action.id_permission === permissions[dataIndex].id_permission) {
              actionsSelected.push(action.action);
            }
          });
          return actionsSelected.length > 0
            ? ` — ${actionsSelected}`
            : ' — Sin acciones seleccionadas';
        },
      },
    },
    {
      name: 'actions',
      label: 'Acciones',
      options: {
        filter: false,
        sort: false,
        empty: true,
        setCellHeaderProps: () => ({
          style: { textAlign: 'center' },
        }),
        setCellProps: () => ({
          style: { textAlign: 'center' },
        }),
        customBodyRenderLite: (dataIndex, rowIndex) => {
          return (
            <>
              {/* Acciones */}
              <Tooltip
                title="Acciones"
                style={
                  searchArr(actionsOnly, 'Acciones de Permisos')
                    ? {}
                    : { display: 'none' }
                }
              >
                <IconButton
                  aria-label="Acciones"
                  className={classes.dark}
                  onClick={() => {
                    let actionsSelected = [];
                    let actionsIndexSelected = [];
                    actionsPermissions.forEach((action) => {
                      if (
                        action.id_permission ===
                        permissions[dataIndex].id_permission
                      ) {
                        actionsSelected.push(action.action);
                        actions.map((subAction, index) => {
                          if (action.action === subAction.action) {
                            actionsIndexSelected.push(index);
                          }
                          return 0;
                        });
                      }
                    });
                    setPermissionsSelected({
                      id_permission: permissions[dataIndex].id_permission,
                      actions: actionsSelected,
                      actionsIndex: actionsIndexSelected,
                    });
                    handleClickOpen();
                  }}
                >
                  <SettingsIcon />
                </IconButton>
              </Tooltip>
              {/* Editar */}
              <Tooltip
                title="Editar"
                style={
                  searchArr(actionsOnly, 'Editar') ? {} : { display: 'none' }
                }
              >
                <IconButton
                  aria-label="editar"
                  className={classes.waring}
                  component={RouterLink}
                  to={'update/' + permissions[dataIndex].id_permission}
                >
                  <Edit />
                </IconButton>
              </Tooltip>
              {/* Eliminar */}
              <Tooltip
                title="Eliminar"
                style={
                  searchArr(actionsOnly, 'Eliminar') ? {} : { display: 'none' }
                }
              >
                <IconButton
                  aria-label="eliminar"
                  className={classes.danger}
                  onClick={() => {
                    clickDelete(dataIndex, rowIndex);
                  }}
                >
                  <Delete />
                </IconButton>
              </Tooltip>
            </>
          );
        },
      },
    },
  ];
  //table actions permissions
  const columnsActions = [
    {
      name: 'id_action',
      label: 'ID',
      options: {
        filter: true,
        sort: true,
        display: false,
      },
    },
    {
      name: 'action',
      label: 'Acción',
      options: {
        filter: true,
        sort: true,
      },
    },
  ];
  const optionsActions = {
    download: false,
    filter: true,
    selectableRowsHeader: true,
    selectableRowsHideCheckboxes: false,
    print: false,
    viewColumns: false,
    rowsSelected: permissionsSelected.actionsIndex,
    onRowSelectionChange: (curRowSelected, allRowsSelected) => {
      let actionsSelected = [];
      let actionsIndexSelected = [];
      allRowsSelected.forEach((itemSelected) => {
        actionsSelected.push(actions[itemSelected.dataIndex].id_action);
        actionsIndexSelected.push(itemSelected.index);
      });
      setPermissionsSelected({
        ...permissionsSelected,
        actions: actionsSelected,
        actionsIndex: actionsIndexSelected,
      });
    },
    customToolbarSelect: ({ data }) => {
      return (
        <Tooltip title="Guardar">
          <IconButton
            onClick={() => {
              savePermissionAction();
            }}
          >
            <SaveIcon />
          </IconButton>
        </Tooltip>
      );
    },
  };
  //useEffects
  useEffect(() => {
    const { api } = config();
    const getActions = async () => {
      await Axios.get(api + 'action/all').then(({ data }) => {
        setActions(data);
      });
    };
    const getActionsPermissions = async () => {
      await Axios.get(
        api + 'action_permission/application_permission/' + id_application
      ).then(({ data }) => {
        setActionsPermissions(data);
      });
    };
    const getPermissions = async () => {
      await Axios.get(api + 'permission/application/' + id_application).then(
        ({ data }) => {
          setPermissions(data);
        }
      );
    };
    getActions();
    getActionsPermissions();
    getPermissions();
  }, [mount, id_application]);
  //render
  return (
    <>
      <DataTable
        title={'Lista de Permisos'}
        data={permissions}
        columns={columns}
      />
      {/* DIALOG DE ACCIONES */}
      <Dialog
        fullWidth
        maxWidth="md"
        open={open}
        onClose={clickClose}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogContent>
          <DataTable
            title={'Lista de Acciones'}
            data={actions}
            columns={columnsActions}
            options={optionsActions}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={clickClose}>Cerrar</Button>
        </DialogActions>
      </Dialog>
      {/* ConfirmDialog */}
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
      {/* Backdrop */}
      <BackgroundLoad open={loading} />
      {/* alert */}
      <Toast toast={toast} setToast={setToast} />
    </>
  );
};

const mapStateToProps = (state) => {
  return state;
};

export default connect(mapStateToProps, null)(PermissionsList);
