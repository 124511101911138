import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import Axios from 'axios';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
} from '@material-ui/core';
import Save from '@material-ui/icons/Save';
import BackgroundLoad from '../../../components/BackgroundLoad';
import Toast from '../../../components/Toast';
import config from '../../../config';

const AddPermission = (props) => {
  const params = useParams();
  //const
  const { actionsOnly } = props;
  const { api, getApiToken, toFormData, searchArr } = config();
  Axios.defaults.headers['Authorization'] = getApiToken();
  const [toast, setToast] = useState({ isOpen: false, message: '', type: '' });
  const [loading, setLoading] = useState(false);
  //data
  const [permission, setPermission] = useState({
    id_application: params.id_application,
    permission: '',
  });
  //metodos
  const changeField = (e) => {
    setPermission({ ...permission, [e.target.name]: e.target.value });
  };
  //peticiones
  const add = () => {
    setLoading(true);
    Axios.post(api + 'permission', toFormData(permission))
      .then(({ data }) => {
        setToast({
          isOpen: true,
          message: data.message,
          type: data.response ? 'success' : 'warning',
        });
        if (data.response) {
          setPermission({
            id_application: params.id_application,
            permission: '',
          });
        }
      })
      .then(() => setLoading(false));
  };
  //render
  return (
    <Box display={searchArr(actionsOnly, 'Agregar') ? '' : 'none'}>
      <Card>
        <CardHeader
          title="Agregar Permiso"
          titleTypographyProps={{ variant: 'h6' }}
        />
        <Divider />
        <CardContent>
          <Grid container justify="center" spacing={3}>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Nombre del permiso"
                name="permission"
                required
                variant="outlined"
                value={permission.permission}
                onChange={changeField}
                autoComplete="off"
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <Box display="flex" justifyContent="flex-end" p={2}>
          <Button
            color="primary"
            variant="contained"
            startIcon={<Save />}
            onClick={add}
          >
            Agregar Permiso
          </Button>
        </Box>
      </Card>
      {/* Backdrop */}
      <BackgroundLoad open={loading} />
      {/* alert */}
      <Toast toast={toast} setToast={setToast} />
    </Box>
  );
};

const mapStateToProps = (state) => {
  return state;
};

export default connect(mapStateToProps, null)(AddPermission);
