import { createStore } from 'redux';

let myApplication = {
  user: {
    first_name: '',
    last_name: '',
    email: '',
    charge: '',
    photo: '',
  },
  permissions: [],
  actionsPermissions: [],
  actionsOnly: [],
};

const reducerUser = (state = myApplication, action) => {
  if (action.type === 'SET_USER') {
    return { ...state, user: action.data };
  }
  if (action.type === 'SET_PERMISSIONS') {
    return { ...state, permissions: action.data };
  }
  if (action.type === 'SET_ACTIONS_PERMISSIONS') {
    return { ...state, actionsPermissions: action.data };
  }
  if (action.type === 'SET_ACTIONS_ONLY') {
    const { actions, permission } = action.data;
    let actionsOnly = [];
    actions.forEach((itm) => {
      if (itm.permission === permission) {
        actionsOnly.push(itm);
      }
    });
    return { ...state, actionsOnly: actionsOnly };
  }
  return state;
};

export default createStore(reducerUser);
