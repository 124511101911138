import React from 'react';
import { Navigate } from 'react-router';
import DashboardLayout from './layouts/DashboardLayout';
import MainLayout from './layouts/MainLayout';
import UserContainer from './containers/users';
import UpdateUser from './containers/users/UpdateUser';
import UserTypes from './containers/users/UserType';
import LoginContainer from './containers/auth/LoginContainer';
import HomeContainer from './containers/home/HomeContainer';
import AccountContainer from './containers/account/AccountContainer';
import ChangePasswordContainer from './containers/account/ChangePasswordContainer';
import NotFoundContainer from './containers/errors/NotFoundContainer';
import RecoverPasswordContainer from './containers/auth/LostPassword/RecoverPasswordContainer';
import ApplicationContainer from './containers/application';
import ApplicationUsersType from './containers/application/userType';
import UsersTypeUpdate from './containers/application/userType/UpdateUserType';
import PermissionsApplication from './containers/application/permissions';
import UpdatePermissionsApplication from './containers/application/permissions/UpdatePermission';
import UpdateApplication from './containers/application/UpdateApplication';
import ChargeContainer from './containers/charge';
import UpdateCharge from './containers/charge/UpdateCharge';
import ActionContainer from './containers/application/action';
import UpdateAction from './containers/application/action/UpdateAction';

const routes = [
  {
    path: 'core',
    element: <DashboardLayout />,
    children: [
      { path: 'applications', element: <ApplicationContainer /> },
      {
        path: 'applications/update/:id_application',
        element: <UpdateApplication />,
      },
      {
        path: 'applications/:id_application/permissions',
        element: <PermissionsApplication />,
      },
      {
        path: 'applications/:id_application/permissions/update/:id_permission',
        element: <UpdatePermissionsApplication />,
      },
      {
        path: 'applications/:id_application/users_type',
        element: <ApplicationUsersType />,
      },
      {
        path: 'applications/:id_application/users_type/update/:id_user_type',
        element: <UsersTypeUpdate />,
      },
      { path: 'account', element: <AccountContainer /> },
      { path: 'change_password', element: <ChangePasswordContainer /> },
      // Catalogs
      { path: 'users', element: <UserContainer /> },
      { path: 'users/update/:id_user', element: <UpdateUser /> },
      { path: 'users/types/:id_user', element: <UserTypes /> },
      { path: 'charges', element: <ChargeContainer /> },
      { path: 'charges/update/:id_charge', element: <UpdateCharge /> },
      { path: 'applications/actions/', element: <ActionContainer /> },
      {
        path: 'applications/actions/update/:id_action',
        element: <UpdateAction />,
      },
      { path: '404', element: <NotFoundContainer /> },
      { path: '/:token', element: <HomeContainer /> },
      { path: '/', element: <HomeContainer /> },
      { path: '/*', element: <Navigate to="/404" /> },
    ],
  },
  {
    path: '/',
    element: <MainLayout />,
    children: [
      { path: 'login', element: <LoginContainer /> },
      { path: 'lost_password', element: <RecoverPasswordContainer /> },
      { path: '404', element: <NotFoundContainer /> },
      { path: '/', element: <Navigate to="/login" /> },
      { path: '/*', element: <Navigate to="/404" /> },
    ],
  },
];

export default routes;
