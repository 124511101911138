import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  logo: {
    width: 300,
    height: 200,
  },
  buttonUpload: {
    height: 52,
  },
}));

export default useStyles;
