import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  paper: {
    width: '100%',
    maxHeight: 435,
  },
}));

export default useStyles;
