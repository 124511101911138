import React, { useState } from 'react';
import { Box, Button, Grid, TextField } from '@material-ui/core';
import CardCustom from '../cardCustom';
import style from './style';

const Step1 = ({ application, setApplication, changeField }) => {
  const classes = style();
  //data
  const [preview, setPreview] = useState('');
  //metodos
  const changeFile = (e) => {
    if (e.target.files[0] !== undefined) {
      setApplication({ ...application, logo: e.target.files[0] });
      let reader = new FileReader();
      reader.onloadend = () => {
        if (reader.readyState === 2) {
          setPreview(reader.result);
        }
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };
  //render
  return (
    <Box py={2}>
      <Grid container spacing={3}>
        <Grid item xs={8}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <TextField
                fullWidth
                label="Nombre de aplicación"
                name="application"
                required
                variant="outlined"
                value={application.application}
                onChange={changeField}
                autoComplete="off"
              />
            </Grid>
            <Grid item xs={12} md={8}>
              <TextField
                fullWidth
                label="URL de aplicación"
                name="url"
                required
                variant="outlined"
                value={application.url}
                onChange={changeField}
                autoComplete="off"
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Button
                variant="contained"
                fullWidth
                component="label"
                className={classes.buttonUpload}
              >
                Subir Imagen
                <input
                  type="file"
                  style={{ display: 'none' }}
                  onChange={changeFile}
                />
              </Button>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Descripción de aplicación"
                name="description"
                multiline
                rows={2}
                required
                variant="outlined"
                value={application.description}
                onChange={changeField}
                autoComplete="off"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <CardCustom
                title={application.application}
                description={application.description}
                image={preview}
                colorText={'grey'}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Step1;
