import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import { Box } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const FormDialog = ({ visibility, toggleVisibility }) => {
  const classes = useStyles();

  return (
    <Dialog
      fullScreen
      open={visibility}
      onClose={toggleVisibility}
      TransitionComponent={Transition}
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={toggleVisibility}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            ACTUALIZACIÓN DE INFORMACIÓN PARA SISTEMA SIGES Y PLANTA DOCENTE
          </Typography>
          <Button autoFocus color="inherit" onClick={toggleVisibility}>
            Cerrar
          </Button>
        </Toolbar>
      </AppBar>
      <Box>
        <iframe
          src="https://docs.google.com/forms/d/e/1FAIpQLSczjtBSAy29bBGwPwhuzGXaXkuV_I8Exp3Dcus1xTw4k4_AAA/viewform?embedded=true"
          style={{
            maxHeight: '100vh',
            height: '100vh',
            width: '100vw',
            margin: '12px',
          }}
          frameborder="0"
        >
          Cargando…
        </iframe>
      </Box>
    </Dialog>
  );
};

export default FormDialog;
