import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import Axios from 'axios';
import {
  AppBar,
  Button,
  Box,
  Container,
  Divider,
  Link,
  Tabs,
  Tab,
  Typography,
} from '@material-ui/core';
import ArrowBack from '@material-ui/icons/ArrowBack';
import TabPanel from '../../../components/TabPanel';
import PermissionsList from './PermissionsList';
import AddPermission from './AddPermission';
import config from '../../../config';
import style from './style';

const PermissionsApplication = (props) => {
  const classes = style();
  const params = useParams();
  //const
  const { actionsOnly, getActionsOnly, actionsPermissions } = props;
  const { searchArr } = config();
  //tabs
  const [value, setValue] = useState(0);
  const changeTab = (event, newValue) => {
    setValue(newValue);
  };
  function a11yProps(index) {
    return {
      id: `nav-tab-${index}`,
      'aria-controls': `nav-tabpanel-${index}`,
    };
  }
  //data
  const [application, setApplication] = useState({
    id_application: params.id_application,
    application: '',
  });
  //useEffects
  useEffect(() => {
    const { api } = config();
    const getApplication = async () => {
      await Axios.get(
        api + 'application/one/' + application.id_application
      ).then(({ data }) => {
        setApplication(data);
      });
    };
    getApplication();
    getActionsOnly({
      actions: actionsPermissions,
      permission: 'Permisos',
    });
  }, [application.id_application, getActionsOnly, actionsPermissions]);
  //render
  return (
    <Container maxWidth={false} className={classes.root}>
      <Box mb={2}>
        <Link component={RouterLink} to="/core/applications" underline="none">
          <Button variant="text" startIcon={<ArrowBack />}>
            Regresar
          </Button>
        </Link>
      </Box>
      <Box width="100%" display="flex">
        <Typography variant="h5" gutterBottom>
          {application.application}{' '}
          <Typography variant="body1" gutterBottom>
            Permisos de la aplicación
          </Typography>
        </Typography>
      </Box>
      <Divider />

      <Box
        className={classes.tabs}
        display={searchArr(actionsOnly, 'Ver') ? '' : 'none'}
      >
        <AppBar position="static">
          <Tabs
            value={value}
            onChange={changeTab}
            indicatorColor="secondary"
            variant="fullWidth"
            aria-label="Tabs Permisos"
          >
            <Tab label="Lista de Permisos" {...a11yProps(0)} />
            <Tab label="Agregar Permiso" {...a11yProps(1)} />
          </Tabs>
        </AppBar>

        <TabPanel value={value} index={0}>
          <PermissionsList />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <AddPermission />
        </TabPanel>
      </Box>
    </Container>
  );
};
const mapDispatchToProps = (dispatch) => ({
  getActionsOnly(data) {
    dispatch({
      type: 'SET_ACTIONS_ONLY',
      data,
    });
  },
});

const mapStateToProps = (state) => {
  return state;
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PermissionsApplication);
