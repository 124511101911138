import React, { useEffect, useState } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import { connect } from 'react-redux';
import Axios from 'axios';
import { Tooltip, IconButton } from '@material-ui/core/';
import Edit from '@material-ui/icons/Edit';
import Delete from '@material-ui/icons/Delete';
import DataTable from '../../components/DataTable/DataTable';
import BackgroundLoad from '../../components/BackgroundLoad';
import ConfirmDialog from '../../components/ConfirmDialog';
import Toast from '../../components/Toast';
import config from '../../config';
import style from './style';

const ChargesList = (props) => {
  const classes = style();
  //const
  const { actionsOnly } = props;
  const { api, getApiToken, searchArr } = config();
  Axios.defaults.headers['Authorization'] = getApiToken();
  const [toast, setToast] = useState({ isOpen: false, message: '', type: '' });
  const [loading, setLoading] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState({ isOpen: false });
  const [mount, setMount] = useState(0);
  //data
  const [charges, setCharges] = useState([{ id_charge: 0 }]);
  //peticiones
  const clickDelete = (index, row) => {
    setConfirmDialog({
      isOpen: true,
      title: 'Advertencia',
      message: '¿Desea eliminar el registro seleccionado?',
      clickConfirm: () => {
        setLoading(true);
        Axios.delete(api + 'charge/' + charges[index].id_charge)
          .then(({ data }) => {
            setToast({
              isOpen: true,
              message: data.message,
              type: data.response ? 'success' : 'warning',
            });
            if (data.response) {
              setMount(mount + 1);
            }
          })
          .then(() => setLoading(false));
      },
    });
  };
  //table
  const columns = [
    {
      name: 'charge',
      label: 'Cargo',
    },
    {
      name: 'description',
      label: 'Descripción',
    },
    {
      name: 'actions',
      label: 'Acciones',
      options: {
        filter: false,
        sort: false,
        empty: true,
        setCellHeaderProps: () => ({
          style: { textAlign: 'center' },
        }),
        setCellProps: () => ({
          style: { textAlign: 'center' },
        }),
        customBodyRenderLite: (dataIndex, rowIndex) => {
          return (
            <>
              {/* Editar */}
              <Tooltip
                title="Editar"
                style={
                  searchArr(actionsOnly, 'Editar') ? {} : { display: 'none' }
                }
              >
                <IconButton
                  aria-label="editar"
                  className={classes.waring}
                  component={RouterLink}
                  to={'update/' + charges[dataIndex].id_charge}
                >
                  <Edit />
                </IconButton>
              </Tooltip>
              {/* Eliminar */}
              <Tooltip
                title="Eliminar"
                style={
                  searchArr(actionsOnly, 'Eliminar') ? {} : { display: 'none' }
                }
              >
                <IconButton
                  aria-label="eliminar"
                  className={classes.danger}
                  onClick={() => {
                    clickDelete(dataIndex, rowIndex);
                  }}
                >
                  <Delete />
                </IconButton>
              </Tooltip>
            </>
          );
        },
      },
    },
  ];
  //useEffects
  useEffect(() => {
    const { api } = config();
    const getCharges = async () => {
      await Axios.get(api + 'charge/all').then(({ data }) => {
        setCharges(data);
      });
    };
    getCharges();
  }, [mount]);
  //render
  return (
    <>
      <DataTable title={'Lista de Cargos'} data={charges} columns={columns} />
      {/* ConfirmDialog */}
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
      {/* Backdrop */}
      <BackgroundLoad open={loading} />
      {/* alert */}
      <Toast toast={toast} setToast={setToast} />
    </>
  );
};

const mapStateToProps = (state) => {
  return state;
};

export default connect(mapStateToProps, null)(ChargesList);
