import React, { useState } from 'react';
import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  Tooltip,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
} from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import DataTable from '../../../../components/DataTable/DataTable';
import SettingsIcon from '@material-ui/icons/Settings';
import style from './style';

const Step3 = ({ actions, permissionsActions, changeData, clickSave }) => {
  const classes = style();
  //const
  const [open, setOpen] = useState(false);
  //data
  const columns = [
    {
      name: 'id_action',
      label: 'ID',
      options: {
        filter: true,
        sort: true,
        display: false,
      },
    },
    {
      name: 'action',
      label: 'Acción',
      options: {
        filter: true,
        sort: true,
      },
    },
  ];
  const options = {
    download: false,
    filter: false,
    selectableRowsHeader: true,
    selectableRowsHideCheckboxes: false,
    print: false,
    viewColumns: false,
    customToolbarSelect: ({ data }) => {
      return (
        <Tooltip title="Guardar">
          <IconButton
            onClick={() => {
              clickSave(data, clickClose);
            }}
          >
            <SaveIcon />
          </IconButton>
        </Tooltip>
      );
    },
  };
  //metodos
  const clickOpen = () => {
    setOpen(true);
  };
  const clickClose = () => {
    setOpen(false);
  };
  const getActionsPermission = (permissionAction) => {
    if (permissionAction[1].length === 0) {
      return ` — Sin acciones seleccionadas`;
    } else {
      let result = [];
      permissionAction[1].forEach((action) => {
        result.push(action.action);
      });
      return ` — ${result}`;
    }
  };
  //render
  return (
    <Container maxWidth="sm">
      <Grid container spacing={3}>
        <List className={classes.root}>
          {permissionsActions.map((permissionAction) => {
            return (
              <div key={permissionAction[0].id_permission}>
                <ListItem alignItems="flex-start">
                  <ListItemText
                    primary={permissionAction[0].permission}
                    secondary={getActionsPermission(permissionAction)}
                  />
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      aria-label="comments"
                      onClick={() => {
                        changeData({
                          name: 'id_permission',
                          value: permissionAction[0].id_permission,
                        });
                        clickOpen();
                      }}
                    >
                      <SettingsIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
                <Divider component="li" />
              </div>
            );
          })}
        </List>
      </Grid>
      {/* DIALOG DE ACCIONES */}
      <Dialog
        fullWidth
        maxWidth="md"
        open={open}
        onClose={clickClose}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogContent>
          <DataTable
            title={'Lista de Acciones'}
            data={actions}
            columns={columns}
            options={options}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={clickClose}>Cerrar</Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default Step3;
