import { makeStyles } from '@material-ui/core';
import { orange, red, grey } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  tabs: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    marginTop: 5,
  },
  dark: {
    color: grey[900],
  },
  waring: {
    color: orange[500],
  },
  danger: {
    color: red[500],
  },
  logo: {
    width: 300,
    height: 200,
  },
  buttonUpload: {
    height: 52,
  },
}));

export default useStyles;
