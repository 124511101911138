import React, { useEffect, useState } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import { connect } from 'react-redux';
import Axios from 'axios';
import moment from 'moment';
import { Tooltip, IconButton, Avatar } from '@material-ui/core/';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import Edit from '@material-ui/icons/Edit';
import Delete from '@material-ui/icons/Delete';
import DataTable from '../../components/DataTable/DataTable';
import BackgroundLoad from '../../components/BackgroundLoad';
import ConfirmDialog from '../../components/ConfirmDialog';
import Toast from '../../components/Toast';
import config from '../../config';
import style from './style';

const UsersList = (props) => {
  const classes = style();
  //const
  const { actionsOnly } = props;
  const { api, images, getApiToken, searchArr } = config();
  Axios.defaults.headers['Authorization'] = getApiToken();
  const [toast, setToast] = useState({ isOpen: false, message: '', type: '' });
  const [loading, setLoading] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState({ isOpen: false });
  const [mount, setMount] = useState(0);
  //data
  const [users, setUsers] = useState([{ id_user: 0 }]);
  //peticiones
  const clickDelete = (index, row) => {
    //esto deberia ir encerrado en algo confirm4
    setConfirmDialog({
      isOpen: true,
      title: 'Advertencia',
      message: '¿Desea eliminar el registro seleccionado?',
      clickConfirm: () => {
        setLoading(true);
        Axios.delete(api + 'user/' + users[index].id_user)
          .then(({ data }) => {
            setToast({
              isOpen: true,
              message: data.message,
              type: data.response ? 'success' : 'warning',
            });
            if (data.response) {
              setMount(mount + 1);
            }
          })
          .then(() => setLoading(false));
      },
    });
  };
  //table
  const columns = [
    {
      name: 'photo',
      label: 'Foto',

      options: {
        filter: false,
        sort: false,
        empty: true,
        setCellHeaderProps: () => ({
          style: { textAlign: 'center' },
        }),
        customBodyRenderLite: (dataIndex, rowIndex) => {
          const classes = style();
          return (
            <Avatar
              style={{
                margin: 'auto',
              }}
              alt={'avatarUserId' + users[dataIndex].id_user}
              src={
                users[dataIndex].photo
                  ? images + '/user/' + users[dataIndex].photo
                  : null
              }
              className={classes.large}
            />
          );
        },
      },
    },
    {
      name: 'first_name',
      label: 'Nombres',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'last_name',
      label: 'Apellidos',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'email',
      label: 'Correo Electrónico',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'gender',
      label: 'Género',
      options: {
        filter: true,
        sort: true,
        display: false,
      },
    },
    {
      name: 'date_birth',
      label: 'Fecha de nacimiento',
      options: {
        filter: true,
        sort: true,
        display: false,
      },
    },
    {
      name: 'date_birth',
      label: 'Edad',
      options: {
        filter: true,
        sort: true,
        display: false,
        customBodyRenderLite: (dataIndex, rowIndex) => {
          let date = users[dataIndex].date_birth;
          return date ? moment().diff(date, 'years') : null;
        },
      },
    },
    {
      name: 'charge',
      label: 'Cargo',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'actions',
      label: 'Acciones',
      options: {
        filter: false,
        sort: false,
        empty: true,
        setCellHeaderProps: () => ({
          style: { textAlign: 'center' },
        }),
        setCellProps: () => ({
          style: { textAlign: 'center' },
        }),
        customBodyRenderLite: (dataIndex, rowIndex) => {
          return (
            <>
              {/* Editar */}
              <Tooltip
                title="Tipos de usarios"
                style={
                  searchArr(actionsOnly, 'Tipos de Usuario de Usuarios')
                    ? {}
                    : { display: 'none' }
                }
              >
                <IconButton
                  aria-label="tipos de usuario"
                  className={classes.dark}
                  component={RouterLink}
                  to={'types/' + users[dataIndex].id_user}
                >
                  <VerifiedUserIcon />
                </IconButton>
              </Tooltip>
              {/* Editar */}
              <Tooltip
                title="Editar"
                style={
                  searchArr(actionsOnly, 'Editar') ? {} : { display: 'none' }
                }
              >
                <IconButton
                  aria-label="editar"
                  className={classes.waring}
                  component={RouterLink}
                  to={'update/' + users[dataIndex].id_user}
                >
                  <Edit />
                </IconButton>
              </Tooltip>
              {/* Eliminar */}
              <Tooltip
                title="Eliminar"
                style={
                  searchArr(actionsOnly, 'Eliminar') ? {} : { display: 'none' }
                }
              >
                <IconButton
                  aria-label="eliminar"
                  className={classes.danger}
                  onClick={() => {
                    clickDelete(dataIndex, rowIndex);
                  }}
                >
                  <Delete />
                </IconButton>
              </Tooltip>
            </>
          );
        },
      },
    },
  ];
  //useEffects
  useEffect(() => {
    const { api } = config();
    const getUsers = async () => {
      await Axios.get(api + 'user/all').then(({ data }) => {
        setUsers(data);
      });
    };
    getUsers();
  }, [mount]);
  //render
  return (
    <>
      <DataTable title={'Lista de Usuarios'} columns={columns} data={users} />
      {/* ConfirmDialog */}
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
      {/* Backdrop */}
      <BackgroundLoad open={loading} />
      {/* alert */}
      <Toast toast={toast} setToast={setToast} />
    </>
  );
};

const mapStateToProps = (state) => {
  return state;
};

export default connect(mapStateToProps, null)(UsersList);
