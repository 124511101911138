import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: { width: '100%' },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  colorApplication: {
    background: '#fff',
  },
}));

export default useStyles;
