import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  root: {},
  title: {
    fontSize: 16,
  },
  copy: {
    marginTop: 10,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  systemName: {
    margin: '0 auto',
    marginTop: '20px',
    maxWidth: '90%',
  },
  logo: {
    maxHeight: 100,
  },
  link: {
    visibility: 'hidden',
  },
}));

export default useStyles;
