import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import Axios from 'axios';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  Link,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from '@material-ui/core';
import clsx from 'clsx';
import ArrowBack from '@material-ui/icons/ArrowBack';
import Edit from '@material-ui/icons/Edit';
import BackgroundLoad from '../../../components/BackgroundLoad';
import Toast from '../../../components/Toast';
import config from '../../../config';
import style from './style';

const ITEM_HEIGHT = 50;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const UpdateUserType = () => {
  const classes = style();
  const params = useParams();
  const id_application = params.id_application;
  const id_user_type = params.id_user_type;
  //const
  const { api, getApiToken } = config();
  Axios.defaults.headers['Authorization'] = getApiToken();
  const [toast, setToast] = useState({ isOpen: false, message: '', Type: '' });
  const [loading, setLoading] = useState(false);
  //data
  const [userType, setUserType] = useState({
    id_application: params.id_application,
    id_user_type: params.id_user_type,
    user_type: '',
    actions_permission: [],
  });
  const [permissions, setPermissions] = useState([{ id_permission: 0 }]);
  const [actionsPermissions, setActionsPermissions] = useState([]);
  //metodos
  const changeField = (e) => {
    setUserType({ ...userType, [e.target.name]: e.target.value });
  };

  const [selectedActionsPermissions, setSelectedActionsPermissions] = useState(
    []
  );
  const handleChange = (event) => {
    setSelectedActionsPermissions(event.target.value);

    let ids = [];
    for (const item of event.target.value) {
      ids.push(item.id_action_permission);
    }
    setUserType({ ...userType, actions_permission: ids });
  };

  //peticiones
  const update = () => {
    setLoading(true);
    Axios.post(api + 'action_permission_user_type/' + id_user_type, userType)
      .then(({ data }) => {
        setToast({
          isOpen: true,
          message: data.message,
          Type: data.response ? 'success' : 'warning',
        });
      })
      .then(() => setLoading(false));
  };
  //useEffects
  useEffect(() => {
    const { api } = config();
    const getUserType = async () => {
      await Axios.get(api + 'user_type/' + id_user_type).then(({ data }) => {
        setUserType({
          id_application: id_application,
          id_user_type: id_user_type,
          user_type: data.user_type,
          actions_permission: [],
        });
        getActionsPermissions();
      });
    };
    const getActionsPermissions = async () => {
      await Axios.get(
        api + 'action_permission/application_permission/' + id_application
      ).then(({ data }) => {
        setActionsPermissions(data);
        getUserActionsPermissions(data);
      });
    };
    const getUserActionsPermissions = async (allActionsPermissions) => {
      await Axios.get(
        api + 'action_permission_user_type/user_type/' + id_user_type
      ).then(({ data }) => {
        let selecteds = [];
        allActionsPermissions.forEach((actionPermission) => {
          let id_action = actionPermission.id_action_permission;
          data.forEach((item) => {
            let id_item = item.id_action_permission;
            if (id_item === id_action) {
              selecteds.push(actionPermission);
            }
          });
        });
        setSelectedActionsPermissions(selecteds);
      });
    };
    const getPermissions = async () => {
      await Axios.get(api + 'permission/application/' + id_application).then(
        ({ data }) => {
          setPermissions(data);
        }
      );
    };
    getUserType();
    getPermissions();
  }, [id_application, id_user_type]);
  //render
  return (
    <>
      <Box mb={2}>
        <Link
          component={RouterLink}
          to={'/core/applications/' + params.id_application + '/users_type/'}
          underline="none"
        >
          <Button variant="text" startIcon={<ArrowBack />}>
            Regresar
          </Button>
        </Link>
      </Box>
      <Card>
        <CardHeader
          title="Modificar Tipo de Usuario"
          titleTypographyProps={{ variant: 'h6' }}
        />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                variant="outlined"
                label="Tipo de Usuario"
                name="user_type"
                value={userType.user_type}
                onChange={changeField}
                required
              />
              {/* <Typography>
                Seleccionar todas las acciones de todos los permisos:{' '}
                <FormControlLabel
                  control={<Checkbox name="chk" color="primary" />}
                  label="Todos"
                />
              </Typography> */}
            </Grid>
            {permissions.map((permission, index) => {
              return (
                <Grid item xs={12} md={3} lg={4} key={permission.id_permission}>
                  <FormHelperText filled className={classes.selectText}>
                    {permission.permission}
                  </FormHelperText>
                  <FormControl
                    className={clsx(classes.formControl, classes.noLabel)}
                    variant="outlined"
                  >
                    <Select
                      id="actions"
                      multiple
                      displayEmpty
                      value={selectedActionsPermissions}
                      onChange={handleChange}
                      input={<OutlinedInput />}
                      renderValue={(selected) => {
                        let selectedNames = [];
                        for (const item of selected) {
                          if (item.id_permission === permission.id_permission) {
                            selectedNames.push(item.action);
                          }
                        }
                        if (selectedNames.length === 0) {
                          return <>Acciones</>;
                        }
                        return selectedNames.join(', ');
                      }}
                      MenuProps={MenuProps}
                      inputProps={{ 'aria-label': 'Without label' }}
                    >
                      <MenuItem disabled value="">
                        Acciones - {permission.permission}
                      </MenuItem>
                      {actionsPermissions.map((actionPermission) => {
                        let action_permission = actionPermission.id_permission;
                        if (action_permission === permission.id_permission) {
                          return (
                            <MenuItem
                              key={actionPermission.id_action_permission}
                              value={actionPermission}
                            >
                              <Checkbox
                                checked={
                                  selectedActionsPermissions.indexOf(
                                    actionPermission
                                  ) > -1
                                }
                              />
                              <ListItemText primary={actionPermission.action} />
                            </MenuItem>
                          );
                        }
                        return null;
                      })}
                    </Select>
                  </FormControl>
                  {/* <FormHelperText>
                    <FormControlLabel
                      control={<Checkbox color="primary" onClick={(e)=>{console.log(e)}}/>}
                      label="Seleccionar Todo"
                      classes={{ label: classes.subLabel }}
                    />
                  </FormHelperText> */}
                </Grid>
              );
            })}
          </Grid>
        </CardContent>
        <Divider />
        <Box display="flex" justifyContent="flex-end" p={2}>
          <Button
            color="primary"
            variant="contained"
            startIcon={<Edit />}
            onClick={update}
          >
            Modificar Tipo de Usuario
          </Button>
        </Box>
      </Card>
      {/* Backdrop */}
      <BackgroundLoad open={loading} />
      {/* alert */}
      <Toast toast={toast} setToast={setToast} />
    </>
  );
};

export default UpdateUserType;
