import { makeStyles } from '@material-ui/core';
import { orange, red, grey } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
  tabs: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    marginTop: 5,
  },
  formControl: {
    minWidth: 120,
  },
  avatar: {
    width: 150,
    height: 150,
  },
  fileUpload: {
    marginTop: 10,
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  dark: {
    color: grey[900],
  },
  waring: {
    color: orange[500],
  },
  danger: {
    color: red[500],
  },
}));

export default useStyles;
