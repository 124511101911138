import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import moment from 'moment';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardHeader,
  CardActions,
  CardContent,
  Divider,
  Grid,
  Typography,
  TextField,
} from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import Save from '@material-ui/icons/Save';
import BackgroundLoad from '../../components/BackgroundLoad';
import Toast from '../../components/Toast';
import config from '../../config';
import style from './style';

const AccountContainer = () => {
  const classes = style();
  //const
  const { api, images, getApiToken, toFormData } = config();
  Axios.defaults.headers['Authorization'] = getApiToken();
  const [toast, setToast] = useState({ isOpen: false, message: '', type: '' });
  const [loading, setLoading] = useState(false);
  //data
  const [user, setUser] = useState({
    id_user: 0,
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    date_birth: moment().subtract(18, 'years').format('YYYY-MM-DD'),
    extension: '',
    photo: '',
    id_charge: 0,
    id_gender: 0,
  });
  const [preview, setPreview] = useState('');
  //metodos
  const changeField = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };
  const changePicker = (newDate) => {
    setUser({ ...user, date_birth: moment(newDate).format('YYYY-MM-DD') });
  };
  const changeFile = (e) => {
    if (e.target.files[0] !== undefined) {
      setUser({ ...user, photo: e.target.files[0] });
      let reader = new FileReader();
      reader.onloadend = () => {
        if (reader.readyState === 2) {
          setPreview(reader.result);
        }
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };
  //peticiones
  const update = () => {
    setLoading(true);
    Axios.post(api + 'user/' + user.id_user, toFormData(user), {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
      .then(({ data }) => {
        setToast({
          isOpen: true,
          message: data.message,
          type: data.response ? 'success' : 'warning',
        });
        if (data.response) {
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }
      })
      .then(() => setLoading(false));
  };
  //useEffects
  useEffect(() => {
    const { api } = config();
    const getUser = async () => {
      await Axios.get(api + 'profile/my_data').then(({ data }) => {
        let date = moment(new Date(data.date_birth)).format('YYYY-MM-DD');
        data.date_birth = date;
        setUser(data);
      });
    };
    getUser();
  }, [user.id_user]);
  //render
  return (
    <>
      <Box className={classes.root}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <Card>
              <CardContent>
                <Box
                  spacing={3}
                  alignItems="center"
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                >
                  <Avatar
                    className={classes.avatar}
                    src={
                      preview
                        ? preview
                        : user.photo && !preview
                        ? images + 'user/' + user.photo
                        : null
                    }
                  />
                  <Typography
                    className={classes.name}
                    color="textPrimary"
                    variant="h6"
                  >
                    {user.first_name}
                    <br />
                    {user.last_name}
                  </Typography>
                  <Typography variant="body1" color="textSecondary">
                    {user.charge}
                  </Typography>
                  <Typography
                    className={classes.dateText}
                    color="textSecondary"
                    variant="body1"
                  ></Typography>
                </Box>
              </CardContent>
              <Divider />
              <CardActions>
                <Button
                  color="primary"
                  fullWidth
                  variant="text"
                  component="label"
                >
                  <input
                    style={{ display: 'none' }}
                    id="upload-photo"
                    name="upload-photo"
                    type="file"
                    onChange={changeFile}
                  />
                  Subir Foto
                </Button>
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs={12} md={8}>
            <Card>
              <CardHeader
                title="Perfil"
                titleTypographyProps={{ variant: 'h6' }}
              />
              <Divider />
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="Nombres"
                      name="first_name"
                      required
                      variant="outlined"
                      value={user.first_name}
                      onChange={changeField}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="Apellidos"
                      name="last_name"
                      required
                      variant="outlined"
                      value={user.last_name}
                      onChange={changeField}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <DatePicker
                      okLabel="Aceptar"
                      cancelLabel="Cancelar"
                      fullWidth
                      maxDate={moment().subtract(18, 'years')}
                      minDate={moment().subtract(100, 'years')}
                      inputVariant="outlined"
                      openTo="year"
                      format="DD/MM/yyyy"
                      label="Fecha de nacimiento"
                      views={['year', 'month', 'date']}
                      name="date_birth"
                      value={moment(user.date_birth)}
                      onChange={changePicker}
                      required
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="Correo Electrónico Institucional"
                      name="email"
                      InputProps={{
                        readOnly: true,
                      }}
                      variant="outlined"
                      value={user.email}
                    />
                  </Grid>
                  <Grid item md={3} xs={6}>
                    <TextField
                      fullWidth
                      label="Teléfono"
                      name="phone"
                      required
                      variant="outlined"
                      value={user.phone}
                      onChange={changeField}
                    />
                  </Grid>
                  <Grid item md={3} xs={6}>
                    <TextField
                      fullWidth
                      label="Extensión"
                      name="extension"
                      required
                      variant="outlined"
                      value={user.extension}
                      onChange={changeField}
                    />
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
              <Box display="flex" justifyContent="flex-end" p={2}>
                <Button
                  color="primary"
                  variant="contained"
                  startIcon={<Save />}
                  onClick={update}
                >
                  Guardar cambios
                </Button>
              </Box>
            </Card>
          </Grid>
        </Grid>
      </Box>
      {/* Backdrop */}
      <BackgroundLoad open={loading} />
      {/* alert */}
      <Toast toast={toast} setToast={setToast} />
    </>
  );
};

export default AccountContainer;
