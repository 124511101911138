import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  AppBar,
  Box,
  Container,
  Divider,
  Tab,
  Tabs,
  Typography,
} from '@material-ui/core';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import TabPanel from '../../components/TabPanel';
import ChargesList from './ChargesList';
import AddCharge from './AddCharge';
import config from '../../config';
import style from './style';

const ChargeContainer = (props) => {
  const classes = style();
  //const
  const { actionsOnly, getActionsOnly, actionsPermissions } = props;
  const { searchArr } = config();
  //tabs
  const [indexTab, setIndexTab] = useState(0);
  const changeTab = (event, newIndexTab) => {
    setIndexTab(newIndexTab);
  };
  function a11yProps(index) {
    return {
      id: `nav-tab-${index}`,
      'aria-controls': `nav-tabpanel-${index}`,
    };
  }
  //useEffect
  useEffect(() => {
    getActionsOnly({
      actions: actionsPermissions,
      permission: 'Cargos',
    });
  }, [getActionsOnly, actionsPermissions]);
  //render
  return (
    <Container maxWidth={false} className={classes.root}>
      <Box width="100%" display="flex">
        <SupervisedUserCircleIcon style={{ fontSize: 40 }} />
        <Typography variant="h4">Cargos</Typography>
      </Box>
      <Divider />

      <Box
        className={classes.tabs}
        display={searchArr(actionsOnly, 'Ver') ? '' : 'none'}
      >
        <AppBar position="static">
          <Tabs
            value={indexTab}
            onChange={changeTab}
            indicatorColor="secondary"
            variant="fullWidth"
            aria-label="Tabs Cargos"
          >
            <Tab label="Lista de Cargos" {...a11yProps(0)} />
            <Tab
              label="Agregar Cargo"
              {...a11yProps(1)}
              style={
                searchArr(actionsOnly, 'Agregar') ? {} : { display: 'none' }
              }
            />
          </Tabs>
        </AppBar>

        <TabPanel value={indexTab} index={0}>
          <ChargesList />
        </TabPanel>
        <TabPanel value={indexTab} index={1}>
          <AddCharge />
        </TabPanel>
      </Box>
    </Container>
  );
};
const mapDispatchToProps = (dispatch) => ({
  getActionsOnly(data) {
    dispatch({
      type: 'SET_ACTIONS_ONLY',
      data,
    });
  },
});

const mapStateToProps = (state) => {
  return state;
};

export default connect(mapStateToProps, mapDispatchToProps)(ChargeContainer);
