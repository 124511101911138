import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Avatar, Box, Divider, List, Typography } from '@material-ui/core';
import SidebarItem from './SidebarItem';
import AppsIcon from '@material-ui/icons/Apps';
import GroupIcon from '@material-ui/icons/Group';
import HomeIcon from '@material-ui/icons/Dashboard';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import style from './style';
import config from '../../config';

const Sidebar = (props) => {
  //menu
  const items = [
    {
      href: '/core',
      icon: HomeIcon,
      title: 'Inicio',
      show: false,
    },
    {
      href: '/core/applications',
      icon: AppsIcon,
      title: 'Aplicaciones',
      show: false,
    },
  ];

  const catalogs = [
    {
      href: '/core/users',
      icon: GroupIcon,
      title: 'Usuarios',
      show: false,
    },
    {
      href: '/core/charges',
      icon: AssignmentIndIcon,
      title: 'Cargos',
      show: false,
    },
  ];
  //const
  const classes = style();
  const { images } = config();
  const user = props.user;
  //data
  const [namePermissions, setNamePermissions] = useState([]);
  //useEffect
  useEffect(() => {
    let allNamePermissions = [];
    props.permissions.forEach((permission) => {
      allNamePermissions.push(permission.permission);
    });
    setNamePermissions(allNamePermissions);
  }, [props.permissions]);
  //render
  return (
    <div className={classes.root}>
      <Box alignItems="center" display="flex" flexDirection="column" p={2}>
        <Avatar
          className={classes.avatar}
          src={user.photo ? images + 'user/' + user.photo : null}
        />
        <Typography className={classes.name} color="textPrimary" variant="h6">
          {user && user.first_name}
          <br />
          {user && user.last_name}
        </Typography>
        <Typography
          className={classes.charge}
          color="textSecondary"
          variant="body2"
        >
          {user && user.charge}
        </Typography>
      </Box>
      <Divider />
      <List component="nav">
        {items.map((item) => {
          if (namePermissions.indexOf(item.title) >= 0) {
            item.show = true;
            return (
              <SidebarItem
                href={item.href}
                key={item.title}
                title={item.title}
                icon={item.icon}
              />
            );
          }
          return null;
        })}
      </List>
      {items.find((itm) => itm.show) && <Divider />}
      <List component="nav">
        {catalogs.map((catalog) => {
          if (namePermissions.indexOf(catalog.title) >= 0) {
            catalog.show = true;
            return (
              <SidebarItem
                href={catalog.href}
                key={catalog.title}
                title={catalog.title}
                icon={catalog.icon}
              />
            );
          }
          return null;
        })}
      </List>
    </div>
  );
};

const mapStateToProps = (state) => {
  return state;
};

export default connect(mapStateToProps, null)(Sidebar);
