import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Axios from 'axios';
import moment from 'moment';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  MenuItem,
  TextField,
} from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import { Save } from '@material-ui/icons';
import BackgroundLoad from '../../components/BackgroundLoad';
import Toast from '../../components/Toast';
import config from '../../config';
import style from './style';

const AddUser = (props) => {
  const classes = style();
  //const
  const { actionsOnly } = props;
  const { api, getApiToken, toFormData, searchArr } = config();
  Axios.defaults.headers['Authorization'] = getApiToken();
  const [toast, setToast] = useState({ isOpen: false, message: '', type: '' });
  const [loading, setLoading] = useState(false);
  //data
  const [genders, setGenders] = useState([]);
  const [charges, setCharges] = useState([]);
  const [user, setUser] = useState({
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    date_birth: moment().subtract(18, 'years').format('YYYY-MM-DD'),
    extension: '',
    photo: '',
    id_charge: 0,
    id_gender: 0,
  });
  const [preview, setPreview] = useState('');
  //metodos
  const changeField = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };
  const changePicker = (newDate) => {
    setUser({ ...user, date_birth: moment(newDate).format('YYYY-MM-DD') });
  };
  const changeFile = (e) => {
    if (e.target.files[0] !== undefined) {
      setUser({ ...user, photo: e.target.files[0] });
      let reader = new FileReader();
      reader.onloadend = () => {
        if (reader.readyState === 2) {
          setPreview(reader.result);
        }
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };
  //peticiones
  const add = () => {
    setLoading(true);
    Axios.post(api + 'user', toFormData(user), {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
      .then(({ data }) => {
        setToast({
          isOpen: true,
          message: data.message,
          type: data.response ? 'success' : 'warning',
        });
        if (data.response) {
          setUser({
            first_name: '',
            last_name: '',
            email: '',
            phone: '',
            date_birth: moment().subtract(18, 'years').format('YYYY-MM-DD'),
            extension: '',
            photo: '',
            id_charge: 0,
            id_gender: 0,
          });
        }
      })
      .then(() => setLoading(false));
  };
  //useEffects
  useEffect(() => {
    const { api } = config();
    const getGenders = async () => {
      await Axios.get(api + 'gender/all').then(({ data }) => {
        setGenders(data);
      });
    };
    getGenders();
    const getCharges = async () => {
      await Axios.get(api + 'charge/all').then(({ data }) => {
        setCharges(data);
      });
    };
    getCharges();
  }, []);
  //render
  return (
    <Box display={searchArr(actionsOnly, 'Agregar') ? '' : 'none'}>
      <Card>
        <CardHeader
          title="Agregar Usuario"
          titleTypographyProps={{ variant: 'h6' }}
        />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Nombres"
                name="first_name"
                required
                variant="outlined"
                value={user.first_name}
                onChange={changeField}
                autoComplete="off"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Apellidos"
                name="last_name"
                required
                variant="outlined"
                value={user.last_name}
                onChange={changeField}
                autoComplete="off"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <DatePicker
                okLabel="Aceptar"
                cancelLabel="Cancelar"
                fullWidth
                maxDate={moment().subtract(18, 'years')}
                minDate={moment().subtract(100, 'years')}
                inputVariant="outlined"
                openTo="year"
                format="DD/MM/yyyy"
                label="Fecha de nacimiento"
                views={['year', 'month', 'date']}
                name="date_birth"
                value={moment(user.date_birth)}
                onChange={changePicker}
                required
              />
            </Grid>
            <Grid item md={3} xs={6}>
              <TextField
                id="outlined-select-currency"
                select
                label="Género"
                fullWidth
                name="id_gender"
                value={user.id_gender}
                onChange={changeField}
                variant="outlined"
                required
              >
                <MenuItem key={0} value={0}>
                  Seleccionar...
                </MenuItem>
                {genders.map((gender, index) => (
                  <MenuItem key={index} value={gender.id_gender}>
                    {gender.gender}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item md={3} xs={6}>
              <TextField
                id="outlined-select-currency"
                select
                label="Cargo"
                fullWidth
                name="id_charge"
                value={user.id_charge}
                onChange={changeField}
                variant="outlined"
                required
              >
                <MenuItem key={0} value={0}>
                  Seleccionar...
                </MenuItem>
                {charges.map((charge, index) => (
                  <MenuItem key={index} value={charge.id_charge}>
                    {charge.charge}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item md={3} xs={6}>
              <TextField
                fullWidth
                label="Teléfono"
                name="phone"
                required
                variant="outlined"
                value={user.phone}
                onChange={changeField}
                autoComplete="off"
              />
            </Grid>
            <Grid item md={3} xs={6}>
              <TextField
                fullWidth
                label="Extensión"
                name="extension"
                required
                variant="outlined"
                value={user.extension}
                onChange={changeField}
                autoComplete="off"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Correo Electrónico Institucional"
                name="email"
                variant="outlined"
                required
                value={user.email}
                onChange={changeField}
                autoComplete="off"
              />
            </Grid>
            <Grid item xs={12}>
              <Box
                alignItems="center"
                display="flex"
                flexDirection="column"
                justifyContent="center"
              >
                <Box>
                  <Avatar
                    variant="square"
                    className={classes.avatar}
                    src={preview ? preview : null}
                  />
                </Box>
                <Box>
                  <label htmlFor="upload-photo">
                    <input
                      style={{ display: 'none' }}
                      id="upload-photo"
                      name="upload-photo"
                      type="file"
                      onChange={changeFile}
                    />
                    <Button
                      color="primary"
                      variant="outlined"
                      component="span"
                      className={classes.fileUpload}
                    >
                      Subir Foto
                    </Button>
                  </label>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <Box display="flex" justifyContent="flex-end" p={2}>
          <Button
            color="primary"
            variant="contained"
            startIcon={<Save />}
            onClick={add}
          >
            Agregar Usuario
          </Button>
        </Box>
      </Card>
      {/* Backdrop */}
      <BackgroundLoad open={loading} />
      {/* alert */}
      <Toast toast={toast} setToast={setToast} />
    </Box>
  );
};

const mapStateToProps = (state) => {
  return state;
};

export default connect(mapStateToProps, null)(AddUser);
