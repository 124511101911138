import React from 'react';
import { Drawer, Toolbar } from '@material-ui/core';
import Sidebar from '../Sidebar/Sidebar';

import style from './style';

export const Sideboard = (props) => {
  const classes = style();
  //render
  return (
    <Drawer
      className={classes.drawer}
      classes={{ paper: classes.drawerPaper }}
      anchor="left"
      variant={props.variant}
      open={props.open}
      onClose={props.onClose ? props.onClose : null}
    >
      <Toolbar />
      <div className={classes.drawerContainer}>
        <Sidebar permissions={props.permissions} user={props.user} />
      </div>
    </Drawer>
  );
};

export default Sideboard;
