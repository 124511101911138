import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import Axios from 'axios';
import {
  Box,
  Button,
  Card,
  CardHeader,
  CardContent,
  Divider,
  Grid,
  Link,
  TextField,
} from '@material-ui/core';
import CardCustom from './stepsApplication/cardCustom';
import ArrowBack from '@material-ui/icons/ArrowBack';
import EditIcon from '@material-ui/icons/Edit';
import BackgroundLoad from '../../components/BackgroundLoad';
import Toast from '../../components/Toast';
import config from '../../config';
import style from './style';

const UpdateApplication = (props) => {
  const classes = style();
  const params = useParams();
  //const
  const { actionsOnly } = props;
  const { api, images, getApiToken, toFormData, searchArr } = config();
  Axios.defaults.headers['Authorization'] = getApiToken();
  const [toast, setToast] = useState({ isOpen: false, message: '', type: '' });
  const [loading, setLoading] = useState(false);
  //data
  const [application, setApplication] = useState({
    id_application: params.id_application,
    application: '',
    url: '',
    description: '',
    logo: '',
    color_card: '#000000',
    color_text: '#dddddd',
  });
  const [preview, setPreview] = useState('');
  //metodos
  const changeField = (e) => {
    setApplication({ ...application, [e.target.name]: e.target.value });
  };
  const changeFile = (e) => {
    if (e.target.files[0] !== undefined) {
      setApplication({ ...application, logo: e.target.files[0] });
      let reader = new FileReader();
      reader.onloadend = () => {
        if (reader.readyState === 2) {
          setPreview(reader.result);
        }
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };
  //peticiones
  const update = () => {
    setLoading(true);
    Axios.post(
      api + 'application/' + application.id_application,
      toFormData(application)
    )
      .then(({ data }) => {
        setToast({
          isOpen: true,
          message: data.message,
          type: data.response ? 'success' : 'warning',
        });
      })
      .then(() => setLoading(false));
  };
  //useEffects
  useEffect(() => {
    const { api } = config();
    const getApplication = async () => {
      await Axios.get(
        api + 'application/one/' + application.id_application
      ).then(({ data }) => {
        setApplication(data);
      });
    };
    getApplication();
  }, [application.id_application]);
  //render
  return (
    <>
      <Box mb={2}>
        <Link component={RouterLink} to="/core/applications" underline="none">
          <Button variant="text" startIcon={<ArrowBack />}>
            Regresar
          </Button>
        </Link>
      </Box>
      <Box display={searchArr(actionsOnly, 'Editar') ? '' : 'none'}>
        <Card>
          <CardHeader
            title="Modificar Cargo"
            titleTypographyProps={{ variant: 'h6' }}
          />
          <Divider />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={8}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={12}>
                    <TextField
                      fullWidth
                      label="Nombre de aplicación"
                      name="application"
                      required
                      variant="outlined"
                      value={application.application}
                      onChange={changeField}
                      autoComplete="off"
                    />
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <TextField
                      fullWidth
                      label="URL de aplicación"
                      name="url"
                      required
                      variant="outlined"
                      value={application.url}
                      onChange={changeField}
                      autoComplete="off"
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Button
                      variant="contained"
                      fullWidth
                      component="label"
                      className={classes.buttonUpload}
                    >
                      Subir Imagen
                      <input
                        type="file"
                        style={{ display: 'none' }}
                        onChange={changeFile}
                      />
                    </Button>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Descripción de aplicación"
                      name="description"
                      multiline
                      rows={2}
                      required
                      variant="outlined"
                      value={application.description}
                      onChange={changeField}
                      autoComplete="off"
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <CardCustom
                      title={application.application}
                      description={application.description}
                      image={
                        preview
                          ? preview
                          : application.logo && !preview
                          ? images + 'application/' + application.logo
                          : 'https://via.placeholder.com/150'
                      }
                      colorText={'grey'}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <Box display="flex" justifyContent="flex-end" p={2}>
            <Button
              color="primary"
              variant="contained"
              startIcon={<EditIcon />}
              onClick={update}
            >
              Modificar Aplicación
            </Button>
          </Box>
        </Card>
      </Box>
      {/* Backdrop */}
      <BackgroundLoad open={loading} />
      {/* alert */}
      <Toast toast={toast} setToast={setToast} />
    </>
  );
};

const mapStateToProps = (state) => {
  return state;
};

export default connect(mapStateToProps, null)(UpdateApplication);
