import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Axios from 'axios';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from '@material-ui/core';
import clsx from 'clsx';
import Save from '@material-ui/icons/Save';
import BackgroundLoad from '../../../components/BackgroundLoad';
import Toast from '../../../components/Toast';
import config from '../../../config';
import style from './style';

const ITEM_HEIGHT = 50;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const AddUserType = () => {
  const classes = style();
  const params = useParams();
  const id_application = params.id_application;
  //const
  const { api, getApiToken } = config();
  Axios.defaults.headers['Authorization'] = getApiToken();
  const [toast, setToast] = useState({ isOpen: false, message: '', Type: '' });
  const [loading, setLoading] = useState(false);
  //data
  const [userType, setUserType] = useState({
    id_application: params.id_application,
    user_type: '',
    actions_permission: [],
  });
  const [permissions, setPermissions] = useState([{ id_permission: 0 }]);
  const [actionsPermissions, setActionsPermissions] = useState([]);
  //metodos
  const changeField = (e) => {
    setUserType({ ...userType, [e.target.name]: e.target.value });
  };

  const [selectedActionsPermissions, setSelectedActionsPermissions] = useState(
    []
  );
  const handleChange = (event) => {
    setSelectedActionsPermissions(event.target.value);

    let ids = [];
    for (const item of event.target.value) {
      ids.push(item.id_action_permission);
    }
    setUserType({ ...userType, actions_permission: ids });
  };

  //peticiones
  const add = () => {
    setLoading(true);
    Axios.post(api + 'action_permission_user_type', userType)
      .then(({ data }) => {
        setToast({
          isOpen: true,
          message: data.message,
          Type: data.response ? 'success' : 'warning',
        });
        if (data.response) {
          setSelectedActionsPermissions([]);
          setUserType({
            id_application: params.id_application,
            user_type: '',
            actions_permission: [],
          });
        }
      })
      .then(() => setLoading(false));
  };
  //useEffects
  useEffect(() => {
    const { api } = config();
    const getActionsPermissions = async () => {
      await Axios.get(
        api + 'action_permission/application_permission/' + id_application
      ).then(({ data }) => {
        setActionsPermissions(data);
      });
    };
    const getPermissions = async () => {
      await Axios.get(api + 'permission/application/' + id_application).then(
        ({ data }) => {
          setPermissions(data);
        }
      );
    };
    getActionsPermissions();
    getPermissions();
  }, [id_application]);
  //render
  return (
    <>
      <Card>
        <CardHeader
          title="Agregar Tipo de Usuario"
          titleTypographyProps={{ variant: 'h6' }}
        />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                variant="outlined"
                label="Tipo de Usuario"
                name="user_type"
                value={userType.user_type}
                onChange={changeField}
                required
              />
              {/* <Typography>
                Seleccionar todas las acciones de todos los permisos:{' '}
                <FormControlLabel
                  control={<Checkbox name="chk" color="primary" />}
                  label="Todos"
                />
              </Typography> */}
            </Grid>
            {permissions.map((permission, index) => {
              return (
                <Grid item xs={12} md={3} lg={4} key={permission.id_permission}>
                  <FormHelperText filled className={classes.selectText}>
                    {permission.permission}
                  </FormHelperText>
                  <FormControl
                    className={clsx(classes.formControl, classes.noLabel)}
                    variant="outlined"
                  >
                    <Select
                      id={'actions' + permission.id_permission}
                      multiple
                      displayEmpty
                      value={selectedActionsPermissions}
                      onChange={handleChange}
                      input={<OutlinedInput />}
                      renderValue={(selected) => {
                        let selectedNames = [];
                        for (const item of selected) {
                          if (item.id_permission === permission.id_permission) {
                            selectedNames.push(item.action);
                          }
                        }
                        if (selectedNames.length === 0) {
                          return <>Acciones</>;
                        }
                        return selectedNames.join(', ');
                      }}
                      MenuProps={MenuProps}
                      inputProps={{ 'aria-label': 'Without label' }}
                    >
                      <MenuItem disabled value="">
                        Acciones - {permission.permission}
                      </MenuItem>
                      {actionsPermissions.map((actionPermission) => {
                        if (
                          actionPermission.id_permission ===
                          permission.id_permission
                        ) {
                          return (
                            <MenuItem
                              key={actionPermission.id_action_permission}
                              value={actionPermission}
                            >
                              <Checkbox
                                checked={
                                  selectedActionsPermissions.indexOf(
                                    actionPermission
                                  ) > -1
                                }
                              />
                              <ListItemText primary={actionPermission.action} />
                            </MenuItem>
                          );
                        }
                        return null;
                      })}
                    </Select>
                  </FormControl>
                  {/* <FormHelperText>
                    <FormControlLabel
                      control={<Checkbox color="primary" onClick={(e)=>{console.log(e)}}/>}
                      label="Seleccionar Todo"
                      classes={{ label: classes.subLabel }}
                    />
                  </FormHelperText> */}
                </Grid>
              );
            })}
          </Grid>
        </CardContent>
        <Divider />
        <Box display="flex" justifyContent="flex-end" p={2}>
          <Button
            color="primary"
            variant="contained"
            startIcon={<Save />}
            onClick={add}
          >
            Agregar Tipo de Usuario
          </Button>
        </Box>
      </Card>
      {/* Backdrop */}
      <BackgroundLoad open={loading} />
      {/* alert */}
      <Toast toast={toast} setToast={setToast} />
    </>
  );
};

export default AddUserType;
