import React, { useEffect, useState } from 'react';
import { NavLink as RouterLink, useParams } from 'react-router-dom';
import Axios from 'axios';
import { IconButton, Tooltip } from '@material-ui/core';
import { Delete, Edit } from '@material-ui/icons';
import DataTable from '../../../components/DataTable/DataTable';
import BackgroundLoad from '../../../components/BackgroundLoad';
import ConfirmDialog from '../../../components/ConfirmDialog';
import Toast from '../../../components/Toast';
import config from '../../../config';
import style from './style';

const UserTypeList = () => {
  const classes = style();
  const params = useParams();
  const id_application = params.id_application;
  //const
  const { api, getApiToken } = config();
  Axios.defaults.headers['Authorization'] = getApiToken();
  const [toast, setToast] = useState({ isOpen: false, message: '', type: '' });
  const [loading, setLoading] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState({ isOpen: false });
  const [mount, setMount] = useState(0);
  //data
  const [usersType, setUsersType] = useState([{ id_user_type: 0 }]);
  //peticiones
  const clickDelete = (index, row) => {
    setConfirmDialog({
      isOpen: true,
      title: 'Advertencia',
      message: '¿Desea eliminar el registro seleccionado?',
      clickConfirm: () => {
        setLoading(true);
        Axios.delete(api + 'user_type/' + usersType[index].id_user_type)
          .then(({ data }) => {
            setToast({
              isOpen: true,
              message: data.message,
              type: data.response ? 'success' : 'warning',
            });
            if (data.response) {
              setMount(mount + 1);
            }
          })
          .then(() => setLoading(false));
      },
    });
  };
  //header
  const columns = [
    {
      name: 'user_type',
      label: 'Tipo de Usuario',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'actions',
      label: 'Acciones',
      options: {
        filter: false,
        sort: false,
        empty: true,
        setCellHeaderProps: () => ({
          style: { textAlign: 'center' },
        }),
        setCellProps: () => ({
          style: { textAlign: 'center' },
        }),
        customBodyRenderLite: (dataIndex, rowIndex) => {
          return (
            <>
              {/* Editar */}
              <Tooltip title="Editar">
                <IconButton
                  aria-label="editar"
                  className={classes.waring}
                  component={RouterLink}
                  to={'update/' + usersType[dataIndex].id_user_type}
                >
                  <Edit />
                </IconButton>
              </Tooltip>
              {/* Eliminar */}
              <Tooltip title="Eliminar">
                <IconButton
                  aria-label="eliminar"
                  className={classes.danger}
                  onClick={() => {
                    clickDelete(dataIndex, rowIndex);
                  }}
                >
                  <Delete />
                </IconButton>
              </Tooltip>
            </>
          );
        },
      },
    },
  ];
  //useEffects
  useEffect(() => {
    const { api } = config();
    const getUsersType = async () => {
      await Axios.get(
        api + 'action_permission_user_type/users_type/' + id_application
      ).then(({ data }) => {
        setUsersType(data);
      });
    };
    getUsersType();
  }, [mount, id_application]);
  //render
  return (
    <>
      <DataTable
        title={'Lista de Tipos de Usuarios'}
        data={usersType}
        columns={columns}
      />
      {/* ConfirmDialog */}
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
      {/* Backdrop */}
      <BackgroundLoad open={loading} />
      {/* alert */}
      <Toast toast={toast} setToast={setToast} />
    </>
  );
};

export default UserTypeList;
