import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Divider,
  Typography,
} from '@material-ui/core';

import style from './style';

const CardApplication = ({
  title,
  description,
  image,
  titleImage,
  colorText,
  auth,
  url,
}) => {
  const classes = style();
  return (
    <Card className={classes.root}>
      <CardMedia className={classes.media} image={image} title={titleImage} />
      <CardContent>
        <Typography className={classes.title} variant="body1" gutterBottom>
          {title}
        </Typography>
        <Typography
          variant="body2"
          style={{ color: `${colorText}` }}
          component="p"
        >
          {description}
        </Typography>
      </CardContent>
      <Divider />
      <CardActions>
        <Button
          fullWidth
          disableElevation
          variant="contained"
          size="medium"
          color="primary"
          onClick={() => {
            auth(url);
          }}
        >
          Ingresar
        </Button>
      </CardActions>
    </Card>
  );
};

CardApplication.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  titleImage: PropTypes.string.isRequired,
  colorText: PropTypes.string,
  auth: PropTypes.func,
  url: PropTypes.string,
};

CardApplication.defaultProps = {
  title: 'Título',
  description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.',
  image: 'https://via.placeholder.com/150',
  titleImage: 'Title Image',
  colorText: '#000',
  auth: () => {},
  url: '#',
};

export default CardApplication;
