import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { Hidden } from '@material-ui/core';
import Navbar from '../../components/Navbar/Navbar';
import Sideboard from '../../components/Sideboard/Sideboard';
import config from '../../config';
import style from './style';

const DashboardLayout = ({
  setUser,
  setPermissions,
  setActionsPermissions,
  permissions,
}) => {
  //const
  const classes = style();
  //menu
  const [open, setOpen] = useState(false);
  const actionOpen = () => {
    setOpen(!open);
  };
  //useEffect
  useEffect(() => {
    const { getUserOnline, getPermissions, getActionsPermissions } = config();
    getUserOnline((data) => {
      setUser(data);
    });
    getPermissions((data) => {
      setPermissions(data);
    });
    getActionsPermissions((data) => {
      setActionsPermissions(data);
    });
  }, [setUser, setPermissions, setActionsPermissions]);
  //render
  return (
    <div className={classes.root}>
      <Navbar actionOpen={actionOpen} />
      {permissions.length > 1 && (
        <>
          <Hidden smDown>
            <Sideboard variant="permanent" open={true} />
          </Hidden>
          <Hidden smUp>
            <Sideboard variant="temporary" open={open} onClose={actionOpen} />
          </Hidden>
        </>
      )}
      <div className={classes.content}>
        <div className={classes.toolbar}></div>
        <Outlet />
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setUser(data) {
    dispatch({
      type: 'SET_USER',
      data,
    });
  },
  setPermissions(data) {
    dispatch({
      type: 'SET_PERMISSIONS',
      data,
    });
  },
  setActionsPermissions(data) {
    dispatch({ type: 'SET_ACTIONS_PERMISSIONS', data });
  },
});

const mapStateToProps = (state) => {
  return state;
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardLayout);
