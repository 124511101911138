import React, { useState } from 'react';
import Axios from 'axios';
import { Link as RouterLink } from 'react-router-dom';
import {
  Box,
  Button,
  Container,
  Grid,
  Link,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab/';
import style from './style';
import config from '../../../config';
import Copyright from '../../../components/Copyright/Copyright';
import BackgroundLoad from '../../../components/BackgroundLoad';
import Toast from '../../../components/Toast';
import logo from '../../../images/logo.png';

const RecoverPasswordContainer = () => {
  const classes = style();
  //const
  const { api, toFormData } = config();
  const [toast, setToast] = useState({ isOpen: false, message: '', type: '' });
  const [loading, setLoading] = useState(false);
  //data
  const [recover, setRecover] = useState({ email: 'correo@dominio.com' });
  const [recoverSuccess, setRecoverSuccess] = useState(false);

  //metodos
  const changeField = (e) => {
    setRecover({ ...recover, [e.target.name]: e.target.value });
  };

  //peticiones
  const submitRecover = (e) => {
    e.preventDefault();
    setLoading(true);
    Axios.post(api + 'recover_password', toFormData(recover))
      .then(({ data }) => {
        if (data.response) {
          setRecoverSuccess(true);
        } else {
          setToast({
            isOpen: true,
            message: data.message,
            type: 'info',
          });
        }
      })
      .then(() => setLoading(false));
  };

  //render
  return (
    <Container maxWidth="sm">
      <Grid
        container
        direction="column"
        alignItems="center"
        justify="center"
        className={classes.root}
      >
        <Grid item>
          <Paper elevation={3}>
            <Box xs={12} px={3} py={4}>
              <form onSubmit={submitRecover}>
                <Box>
                  <Typography
                    color="textPrimary"
                    component="h1"
                    variant="h5"
                    align="center"
                    gutterBottom
                    className={classes.textBold}
                  >
                    ¿Has olvidado tu contraseña?
                  </Typography>
                  <Box width="100%" display="flex" justifyContent="center">
                    <img
                      className={classes.logo}
                      src={logo}
                      alt="logo ricaldone"
                    />
                  </Box>
                  <Typography variant="subtitle1" align="center" gutterBottom>
                    ¡Nos pasa a los mejores! Ingrese su dirección de correo
                    electrónico para poder acceder nuevamente a su cuenta
                  </Typography>
                </Box>
                {recoverSuccess ? (
                  <Box mt={3}>
                    <Alert variant="filled" severity="success">
                      <AlertTitle>Operación Exitosa</AlertTitle>
                      Se ha enviado un correo electrónico a{' '}
                      <b>{recover.email}</b>, favor revisar.
                    </Alert>
                  </Box>
                ) : (
                  <>
                    <TextField
                      fullWidth
                      label="Correo electrónico"
                      margin="normal"
                      name="email"
                      type="email"
                      variant="outlined"
                      value={recover.email}
                      onChange={changeField}
                      autoComplete="off"
                    />
                    <Box my={2}>
                      <Button
                        color="primary"
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                      >
                        Enviar
                      </Button>
                    </Box>
                  </>
                )}
              </form>
              <Box mt={4}>
                <Typography>
                  ¿Ya recuerda la contraseña?{' '}
                  <Link
                    component={RouterLink}
                    to="/login"
                    className={classes.link}
                    underline="always"
                  >
                    Iniciar Sesión
                  </Link>
                </Typography>
              </Box>
            </Box>
          </Paper>
        </Grid>
        <Grid item>
          <Box xs={12} mt={8} className={classes.copy}>
            <Copyright />
          </Box>
        </Grid>
      </Grid>
      {/* Backdrop */}
      <BackgroundLoad open={loading} />
      {/* alert */}
      <Toast toast={toast} setToast={setToast} />
    </Container>
  );
};

export default RecoverPasswordContainer;
