import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  AppBar,
  Box,
  Container,
  Divider,
  Tab,
  Tabs,
  Typography,
  Button,
  Link,
} from '@material-ui/core';
import ArrowBack from '@material-ui/icons/ArrowBack';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import TabPanel from '../../../components/TabPanel';
import ActionsList from './ActionsList';
import AddAction from './AddAction';
import config from '../../../config';
import style from './style';

const ActionContainer = (props) => {
  const classes = style();
  //const
  const { actionsOnly, getActionsOnly, actionsPermissions } = props;
  const { searchArr } = config();
  //tabs
  const [indexTab, setIndexTab] = useState(0);
  const changeTab = (event, newIndexTab) => {
    setIndexTab(newIndexTab);
  };
  function a11yProps(index) {
    return {
      id: `nav-tab-${index}`,
      'aria-controls': `nav-tabpanel-${index}`,
    };
  }
  //useEffect
  useEffect(() => {
    getActionsOnly({
      actions: actionsPermissions,
      permission: 'Acciones',
    });
  }, [getActionsOnly, actionsPermissions]);
  //render
  return (
    <Container maxWidth={false} className={classes.root}>
      <Box
        alignItems="center"
        width="100%"
        display="flex"
        justifyContent="space-between"
        mb={2}
      >
        <Box flexGrow="1">
          <Box width="100%" display="flex">
            <SupervisedUserCircleIcon style={{ fontSize: 40 }} />
            <Typography variant="h4">Acciones</Typography>
          </Box>
        </Box>
        <Box>
          <Link component={RouterLink} to="/core/applications" underline="none">
            <Button variant="text" startIcon={<ArrowBack />}>
              Regresar
            </Button>
          </Link>
        </Box>
      </Box>
      <Divider />

      <Box
        className={classes.tabs}
        display={searchArr(actionsOnly, 'Ver') ? '' : 'none'}
      >
        <AppBar position="static">
          <Tabs
            value={indexTab}
            onChange={changeTab}
            indicatorColor="secondary"
            variant="fullWidth"
            aria-label="Tabs Acciones"
          >
            <Tab label="Lista de Acciones" {...a11yProps(0)} />
            <Tab label="Agregar Acción" {...a11yProps(1)} />
          </Tabs>
        </AppBar>

        <TabPanel value={indexTab} index={0}>
          <ActionsList />
        </TabPanel>
        <TabPanel value={indexTab} index={1}>
          <AddAction />
        </TabPanel>
      </Box>
    </Container>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getActionsOnly(data) {
    dispatch({
      type: 'SET_ACTIONS_ONLY',
      data,
    });
  },
});

const mapStateToProps = (state) => {
  return state;
};

export default connect(mapStateToProps, mapDispatchToProps)(ActionContainer);
