import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    // height: '100%',
    // overflow: 'hidden',
    // width: '100%',
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    // paddingTop: 64,
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 256,
    },
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
  },
  content: {
    flexGrow: 1,
    // flex: '1 1 auto',
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
    // height: '100%',
    // overflow: 'auto',
  },
  toolbar: theme.mixins.toolbar,
}));

export default useStyles;
