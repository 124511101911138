import React from 'react';
import { Typography, Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';

const Toast = ({ toast, setToast }) => {
  const closeToast = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setToast({ ...toast, isOpen: false });
  };
  const titleToast = (type) => {
    switch (type) {
      case 'success':
        return 'Éxito';
      case 'info':
        return 'Información';
      case 'warning':
        return 'Advertencia';
      case 'error':
        return 'Error';
      default:
        return 'Información';
    }
  };
  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={toast.isOpen}
        autoHideDuration={3000}
        onClose={closeToast}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          severity={toast.type}
          onClose={closeToast}
        >
          <AlertTitle>{titleToast(toast.type)}</AlertTitle>
          <Typography variant="body1" gutterBottom>
            {toast.message}
          </Typography>
        </MuiAlert>
      </Snackbar>
    </>
  );
};
export default Toast;
