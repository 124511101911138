import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Axios from 'axios';
import PropTypes from 'prop-types';
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  MenuItem,
  Menu,
  Tooltip,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import ConfirmDialog from '../../components/ConfirmDialog';
// import LockIcon from '@material-ui/icons/Lock';
import style from './style';
import config from '../../config';
import logoBrand from '../../images/logoBrand.png';

const Navbar = (props) => {
  const classes = style();
  let navigate = useNavigate();
  /* permisos */
  const { api, getApiToken } = config();
  useEffect(() => {
    if (getApiToken() === undefined || getApiToken() === null) {
      navigate('/login');
    }
    Axios.defaults.headers['Authorization'] = getApiToken();
  });
  //data
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: '',
    message: '',
  });
  //peticiones
  const logOut = () => {
    Axios.post(api + 'profile/logout', null).then(() => {
      sessionStorage.removeItem('core_token');
      navigate('/login');
    });
  };
  const cerrarSession = () => {
    handleClose();
    //esto deberia ir encerrado en algo confirm4
    setConfirmDialog({
      isOpen: true,
      title: 'Advertencia',
      message: '¿Desea cerrar sesión?',
      clickConfirm: logOut,
    });
  };
  //--------------------------------------------------
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleRedirectProfile = () => {
    handleClose();
    navigate('/core/account');
  };

  const handleRedirectChangePassword = () => {
    handleClose();
    navigate('/core/change_password');
  };

  return (
    <AppBar position="fixed" className={classes.appBar} elevation={0}>
      <Toolbar>
        <IconButton
          edge="start"
          className={classes.menuButton}
          color="inherit"
          aria-label="open drawer"
          onClick={() => props.actionOpen()}
        >
          <MenuIcon />
        </IconButton>
        <Typography className={classes.title} variant="h6" noWrap>
          <img src={logoBrand} className={classes.logoBrand} alt="logo" />
        </Typography>
        {/* Button profile */}
        <>
          <Tooltip title="Cuenta">
            <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <AccountCircle className={classes.mrDos} />
              <Typography>{props.user.email}</Typography>
            </IconButton>
          </Tooltip>
          {/* <Tooltip title="Cerrar Sesión" placement="bottom-start">
            <IconButton color="inherit" onClick={cerrarSession}>
              <ExitToAppIcon />
            </IconButton>
          </Tooltip> */}
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={open}
            onClose={handleClose}
          >
            <MenuItem onClick={handleRedirectProfile}>
              <AccountCircle className={classes.mrDos} /> Perfil
            </MenuItem>
            {/* <MenuItem onClick={handleRedirectChangePassword}>
              <LockIcon className={classes.mrDos} /> Cambiar Contraseña
            </MenuItem> */}
            <MenuItem onClick={cerrarSession}>
              <ExitToAppIcon className={classes.mrDos} /> Cerrar Sesión
            </MenuItem>
          </Menu>
        </>
      </Toolbar>
      {/* ConfirmDialog */}
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </AppBar>
  );
};

Navbar.propsTypes = {
  actionOpen: PropTypes.func,
};

Navbar.defaultProps = {
  actionOpen: () => {},
};

const mapStateToProps = (state) => {
  return state;
};

export default connect(mapStateToProps, null)(Navbar);
