import React, { useState } from 'react';
import { connect } from 'react-redux';
import Axios from 'axios';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Step,
  StepLabel,
  Stepper,
  Typography,
  Box,
} from '@material-ui/core';
import Step1 from './stepsApplication/step1';
import Step2 from './stepsApplication/step2';
import Step3 from './stepsApplication/step3';
import BackgroundLoad from '../../components/BackgroundLoad';
import ConfirmDialog from '../../components/ConfirmDialog';
import Toast from '../../components/Toast';
import config from '../../config';
import style from './style';

const AddApplication = (props) => {
  const classes = style();
  const [activeStep, setActiveStep] = useState(0);
  const steps = [
    'Información de la aplicación',
    'Creación de los permisos',
    'Asignación de acciones a los permisos',
  ];
  //const
  const { actionsOnly } = props;
  const { api, getApiToken, toFormData, searchArr } = config();
  Axios.defaults.headers['Authorization'] = getApiToken();
  const [toast, setToast] = useState({ isOpen: false, message: '', type: '' });
  const [confirmDialog, setConfirmDialog] = useState({ isOpen: false });
  const [loading, setLoading] = useState(false);
  //data step1
  const [application, setApplication] = useState({
    id_application: 0,
    application: '',
    url: '',
    description: '',
    logo: '',
    color_card: '#000000',
    color_text: '#dddddd',
  });
  //data step2
  const [permission, setPermission] = useState({
    id_application: 0,
    id_permission: 0,
    permission: '',
  });
  const [permissions, setPermissions] = useState([]);
  //data step3
  const [actions, setActions] = useState([]);
  const [permissionsActions, setPermissionsActions] = useState([]);
  const [permissionActions, setPermissionActions] = useState({
    id_permission: 0,
    actions: [],
  });
  //metodos
  const changeField = (e) => {
    //step1
    if (activeStep === 0) {
      setApplication({ ...application, [e.target.name]: e.target.value });
    }
    //step2
    if (activeStep === 1) {
      setPermission({ ...permission, [e.target.name]: e.target.value });
    }
  };
  const changeData = (props) => {
    //step3
    if (activeStep === 2) {
      setPermissionActions({
        ...permissionActions,
        [props.name]: props.value,
      });
    }
  };
  //peticiones
  //step1
  const addApplication = () => {
    setLoading(true);
    Axios.post(api + 'application', toFormData(application))
      .then(({ data }) => {
        setToast({
          isOpen: true,
          message: data.message,
          type: data.response ? 'success' : 'warning',
        });
        if (data.response) {
          let id = data.id_application;
          setApplication({ ...application, id_application: id });
          setPermission({ ...permission, id_application: id });
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
      })
      .then(() => setLoading(false));
  };
  //step2
  const getPermissions = () => {
    Axios.get(
      api + 'permission/application/' + application.id_application
    ).then(({ data }) => {
      setPermissions(data);
    });
  };
  const addPermission = () => {
    setLoading(true);
    Axios.post(api + 'permission', toFormData(permission))
      .then(({ data }) => {
        setToast({
          isOpen: true,
          message: data.message,
          type: data.response ? 'success' : 'warning',
        });
        if (data.response) {
          setPermission({ ...permission, permission: '' });
          getPermissions();
        }
      })
      .then(() => setLoading(false));
  };
  const deletePermission = (id_permission) => {
    setConfirmDialog({
      isOpen: true,
      title: 'Advertencia',
      message: '¿Desea eliminar el registro seleccionado?',
      clickConfirm: () => {
        setLoading(true);
        Axios.delete(api + 'permission/' + id_permission)
          .then(({ data }) => {
            setToast({
              isOpen: true,
              message: data.message,
              type: data.response ? 'success' : 'warning',
            });
            if (data.response) {
              getPermissions();
            }
          })
          .then(() => setLoading(false));
      },
    });
  };
  //step3
  const getActions = () => {
    Axios.get(api + 'action/all').then(({ data }) => {
      setActions(data);
    });
  };
  const getPermissionsActions = () => {
    let id = application.id_application;
    Axios.get(api + 'action_permission/application/' + id).then(({ data }) => {
      setPermissionsActions(data);
    });
  };
  const savePermissionAction = (indexRows, closeModal) => {
    changeData({ name: 'actions', value: [] });
    indexRows.forEach((row) => {
      permissionActions.actions.push(actions[row.index].id_action);
    });
    Axios.post(api + 'action_permission', permissionActions).then(
      ({ data }) => {
        setToast({
          isOpen: true,
          message: data.message,
          type: data.response ? 'success' : 'warning',
        });
        if (data.response) {
          closeModal();
          setPermissionActions({ id_permission: 0, actions: [] });
          getPermissionsActions();
        }
      }
    );
  };
  //tabs
  const clickNext = () => {
    //step 1
    if (activeStep === 0) {
      //sin agregar aplicacion
      if (application.id_application === 0) {
        addApplication();
      } else {
        //si ya se agrego, solo next step
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    }
    //step 2
    if (activeStep === 1) {
      if (permissions.length === 0) {
        setToast({
          isOpen: true,
          message: 'Debe agregar al menos un permiso.',
          type: 'info',
        });
      } else {
        getActions();
        getPermissionsActions();
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    }
    //step 3
    if (activeStep === 2) {
      clickReset();
      setToast({
        isOpen: true,
        message: 'La aplicación ha sido creada correctamente.',
      });
      setApplication({
        id_application: 0,
        application: '',
        url: '',
        description: '',
        logo: '',
        color_card: '#000000',
        color_text: '#dddddd',
      });
      setPermissions([]);
    }
  };
  const clickBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const clickReset = () => {
    setActiveStep(0);
  };
  const getStepContent = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return (
          <Step1
            application={application}
            setApplication={setApplication}
            changeField={changeField}
          />
        );
      case 1:
        return (
          <Step2
            permission={permission}
            permissions={permissions}
            changeField={changeField}
            clickAdd={addPermission}
            clickDelete={deletePermission}
          />
        );
      case 2:
        return (
          <Step3
            permissionsActions={permissionsActions}
            actions={actions}
            changeData={changeData}
            clickSave={savePermissionAction}
          />
        );
      default:
        return 'Unknown stepIndex';
    }
  };
  //render
  return (
    <Box
      className={classes.root}
      display={searchArr(actionsOnly, 'Agregar') ? '' : 'none'}
    >
      <Card>
        <CardHeader
          title="Agregar Aplicación"
          titleTypographyProps={{ variant: 'h6' }}
        />
        <Divider />
        <CardContent>
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <div>
            {activeStep === steps.length ? (
              <div>
                <Typography
                  className={classes.instructions}
                  component={'span'}
                  variant={'body2'}
                >
                  Todos los fueron completados
                </Typography>
                <Button onClick={clickReset}>Reiniciar</Button>
              </div>
            ) : (
              <div>
                <Typography
                  className={classes.instructions}
                  component={'span'}
                  variant={'body2'}
                >
                  {getStepContent(activeStep)}
                </Typography>
                <div>
                  <Button
                    disabled={activeStep === 0}
                    onClick={clickBack}
                    className={classes.backButton}
                  >
                    Regresar
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={clickNext}
                  >
                    {activeStep === steps.length - 1
                      ? 'Finalizar'
                      : 'Siguiente'}
                  </Button>
                </div>
              </div>
            )}
          </div>
        </CardContent>
      </Card>
      {/* ConfirmDialog */}
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
      {/* Backdrop */}
      <BackgroundLoad open={loading} />
      {/* alert */}
      <Toast toast={toast} setToast={setToast} />
    </Box>
  );
};

const mapStateToProps = (state) => {
  return state;
};

export default connect(mapStateToProps, null)(AddApplication);
