import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import Axios from 'axios';
import {
  Box,
  Grid,
  TextField,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Link,
  Button,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import ArrowBack from '@material-ui/icons/ArrowBack';
import BackgroundLoad from '../../../components/BackgroundLoad';
import Toast from '../../../components/Toast';
import config from '../../../config';

const UpdateAction = (props) => {
  const params = useParams();
  //const
  const { actionsOnly } = props;
  const { api, getApiToken, toFormData, searchArr } = config();
  Axios.defaults.headers['Authorization'] = getApiToken();
  const [toast, setToast] = useState({ isOpen: false, message: '', type: '' });
  const [loading, setLoading] = useState(false);
  //data
  const [action, setAction] = useState({
    id_action: params.id_action,
    action: '',
    description: '',
  });
  //metodos
  const changeField = (e) => {
    setAction({ ...action, [e.target.name]: e.target.value });
  };
  //peticiones
  const update = () => {
    setLoading(true);
    Axios.post(api + 'action/' + action.id_action, toFormData(action))
      .then(({ data }) => {
        setToast({
          isOpen: true,
          message: data.message,
          type: data.response ? 'success' : 'warning',
        });
      })
      .then(() => setLoading(false));
  };
  //useEffects
  useEffect(() => {
    const { api } = config();
    const getAction = async () => {
      await Axios.get(api + 'action/one/' + action.id_action).then(
        ({ data }) => {
          setAction(data);
        }
      );
    };
    getAction();
  }, [action.id_action]);
  //render
  return (
    <>
      <Box mb={2}>
        <Link
          component={RouterLink}
          to="/core/applications/actions"
          underline="none"
        >
          <Button variant="text" startIcon={<ArrowBack />}>
            Regresar
          </Button>
        </Link>
      </Box>
      <Box display={searchArr(actionsOnly, 'Editar') ? '' : 'none'}>
        <Card>
          <CardHeader
            title="Modificar Acción"
            titleTypographyProps={{ variant: 'h6' }}
          />
          <Divider />
          <CardContent>
            <Grid container justify="center" spacing={3}>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Nombre del acción"
                  name="action"
                  required
                  variant="outlined"
                  value={action.action}
                  onChange={changeField}
                  autoComplete="off"
                />
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <Box display="flex" justifyContent="flex-end" p={2}>
            <Button
              color="primary"
              variant="contained"
              startIcon={<EditIcon />}
              onClick={update}
            >
              Modificar Acción
            </Button>
          </Box>
        </Card>
      </Box>
      {/* Backdrop */}
      <BackgroundLoad open={loading} />
      {/* alert */}
      <Toast toast={toast} setToast={setToast} />
    </>
  );
};

const mapStateToProps = (state) => {
  return state;
};

export default connect(mapStateToProps, null)(UpdateAction);
