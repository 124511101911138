import { makeStyles } from '@material-ui/core';
import { orange, red, grey } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    paddingLeft: 0,
    paddingRight: 0,
  },
  formControl: {
    width: '100%',
  },
  noLabel: {
    // marginTop: theme.spacing(3),
  },
  subLabel: {
    fontSize: '0.85rem',
    color: grey[900],
  },
  selectText: {
    fontSize: '1rem',
    fontWeight: 'bold',
    color: grey[900],
  },
  dark: {
    color: grey[900],
  },
  waring: {
    color: orange[500],
  },
  danger: {
    color: red[500],
  },
}));

export default useStyles;
