import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Divider,
  makeStyles,
  Typography,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 400,
    margin: '0 auto',
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  title: {
    fontWeight: 'bold',
  },
}));

const CardCustom = ({ title, description, image, colorText }) => {
  const classes = useStyles();
  //render
  return (
    <Card className={classes.root}>
      <CardMedia
        className={classes.media}
        image={image ? image : 'https://picsum.photos/300/200'}
      />
      <CardContent>
        <Typography className={classes.title} variant="body1" gutterBottom>
          {title}
        </Typography>
        <Typography
          variant="body2"
          style={{ color: `${colorText}` }}
          component="p"
        >
          {description}
        </Typography>
      </CardContent>
      <Divider />
      <CardActions>
        <Button
          fullWidth
          disableElevation
          variant="contained"
          size="medium"
          color="primary"
        >
          Entrar
        </Button>
      </CardActions>
    </Card>
  );
};

CardCustom.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  colorText: PropTypes.string.isRequired,
};
CardCustom.defaultProps = {
  title: 'Título',
  description:
    'Lorem ipsum dolor sit amet consectetur adipisicing elit. Accusantium id expedita corrupti possimus amet et.',
  image: 'https://via.placeholder.com/150',
  colorText: '#000',
};

export default CardCustom;
