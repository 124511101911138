import React, { useState } from 'react';
import Axios from 'axios';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Container,
  Grid,
  Link,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core';
import style from './style';
import config from '../../config';
import Copyright from '../../components/Copyright/Copyright';
import BackgroundLoad from '../../components/BackgroundLoad';
import Toast from '../../components/Toast';
import logo from '../../images/logo.png';

const LoginContainer = () => {
  const classes = style();
  let navigate = useNavigate();
  //const
  const { api, setApiToken, toFormData } = config();
  const [toast, setToast] = useState({ isOpen: false, message: '', type: '' });
  const [loading, setLoading] = useState(false);
  //data
  const [login, setLogin] = useState({
    user: '',
    password: '',
  });

  //metodos
  const changeField = (e) => {
    setLogin({ ...login, [e.target.name]: e.target.value });
  };

  //peticiones
  const submitLogin = (e) => {
    e.preventDefault();
    if (!login.user && !login.password ) {
      setToast({
            isOpen: true,
            message: "Debes ingresar tus credenciales",
            type: 'warning',
      });
      return;
    }
    setLoading(true);
    Axios.post(api + 'login', toFormData(login))
      .then(({ data }) => {
        if (data.response) {
          setApiToken(data.api_token);
          navigate('/core');
        } else {
          setToast({
            isOpen: true,
            message: data.message,
            type: 'info',
          });
          setLoading(false);
        }
      })
      .catch(() => {
        setLoading(false);
        setToast({
            isOpen: true,
            message: "Ha ocurrido un error inesperado. Intentalo más tarde.",
            type: 'error',
        });
      });
  };
  //render
  return (
    <Container>
      <Grid
        container
        direction="column"
        alignItems="center"
        justify="center"
        className={classes.root}
      >
        <Grid item>
          <Paper elevation={4}>
            <Box px={5} py={4}>
              <form onSubmit={submitLogin}>
                <Box mb={3}>
                  <Box width="100%" display="flex" justifyContent="center">
                    <img
                      className={classes.logo}
                      src={logo}
                      alt="logo ricaldone"
                    />
                  </Box>
                  <Typography
                    color="textPrimary"
                    component="h1"
                    variant="h5"
                    gutterBottom
                    align="center"
                    className={classes.systemName}
                  >
                    Bienvenido al sistema de autenticación CORE
                  </Typography>
                </Box>
                <TextField
                  fullWidth
                  label="Usuario"
                  margin="normal"
                  name="user"
                  type="text"
                  variant="outlined"
                  value={login.user}
                  onChange={changeField}
                  autoComplete="off"
                />
                <TextField
                  fullWidth
                  label="Contraseña"
                  margin="normal"
                  name="password"
                  type="password"
                  variant="outlined"
                  value={login.password}
                  onChange={changeField}
                />
                <Box my={2}>
                  <Button
                    color="primary"
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Iniciar Sesión
                  </Button>
                </Box>
                <Typography color="textSecondary" variant="body1">
                  <Link
                    component={RouterLink}
                    to="/lost_password"
                    className={classes.link}
                  >
                    ¿Has olvidado tu contraseña?
                  </Link>
                </Typography>
              </form>
            </Box>
          </Paper>
        </Grid>
        <Grid item>
          <Box mt={8} className={classes.copy}>
            <Copyright />
          </Box>
        </Grid>
      </Grid>
      {/* Backdrop */}
      <BackgroundLoad open={loading} />
      {/* alert */}
      <Toast toast={toast} setToast={setToast} />
    </Container>
  );
};

export default LoginContainer;
