import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import style from './style';
import Slide from '@material-ui/core/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ConfirmDialog = ({ confirmDialog, setConfirmDialog }) => {
  const classes = style();

  const handleEntering = () => {};

  const handleCancel = () => {
    if (confirmDialog.clickCancel !== undefined) {
      confirmDialog.clickCancel();
    } else {
      setConfirmDialog({ ...confirmDialog, isOpen: false });
    }
  };

  const handleConfirm = () => {
    setConfirmDialog({ ...confirmDialog, isOpen: false });
    confirmDialog.clickConfirm();
  };

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="xs"
      TransitionComponent={Transition}
      onEntering={handleEntering}
      aria-labelledby="confirmation-dialog-title"
      open={confirmDialog.isOpen}
      className={classes.paper}
    >
      <DialogTitle id="confirmation-dialog-title">
        {confirmDialog.title}
      </DialogTitle>
      <DialogContent dividers>
        <Typography variant="body1">{confirmDialog.message}</Typography>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleCancel} color="primary">
          Cancelar
        </Button>
        <Button onClick={handleConfirm} color="primary">
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
