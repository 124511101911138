import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    minHeight: '100vh',
    display: 'flex',
    justifyItems: 'center',
    alignItems: 'center',
  },
}));

export default useStyles;
