import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
  },
  paper: {
    padding: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  avatar: {
    width: 100,
    height: 100,
  },
}));

export default useStyles;
