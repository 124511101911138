import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 40,
  },
  textBold: {
    fontWeight: 'bold',
  },
  copy: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: 16,
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  logo: {
    maxHeight: 150,
    marginBottom: '0.5rem',
  },
}));

export default useStyles;
