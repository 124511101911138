import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(1),
  },
  title: {
    marginRight: 'auto',
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64,
  },
  name: {
    paddingTop: 5,
    textAlign: 'center',
    lineHeight: 1.3,
  },
  charge: {
    paddingTop: 5,
    textAlign: 'center',
  },
  // active: {
  //   color: theme.palette.secondary.main,
  //   '& $title': {
  //     fontWeight: theme.typography.fontWeightMedium,
  //   },
  //   '& $icon': {
  //     color: theme.palette.secondary.main,
  //   },
  // },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

export default useStyles;
