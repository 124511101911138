import React from 'react';
import { Provider } from 'react-redux';
import { useRoutes } from 'react-router-dom';
import { CssBaseline, ThemeProvider } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import store from './store';
import theme from './theme';
import routes from './routes';
import MomentUtils from '@date-io/moment';
import 'moment/locale/es';

const App = () => {
  const routing = useRoutes(routes);
  return (
    <Provider store={store}>
      <MuiPickersUtilsProvider locale="es" utils={MomentUtils}>
        <ThemeProvider theme={theme}>
          {routing}
          <CssBaseline />
        </ThemeProvider>
      </MuiPickersUtilsProvider>
    </Provider>
  );
};

export default App;
