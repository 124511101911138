import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Axios from 'axios';
import { Box, Button, Grid, Typography } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import AppsIcon from '@material-ui/icons/Apps';
import CardApplication from '../../components/Cards/CardApplication';
import BackgroundLoad from '../../components/BackgroundLoad';
import config from '../../config';
import style from './style';
import FormDialog from './FormDialog';

const HomeContainer = () => {
  //redireccionamiento
  const classes = style();
  let navigate = useNavigate();
  let params = useParams();
  //const
  const { images, getApiToken } = config();
  Axios.defaults.headers['Authorization'] = getApiToken();
  const [load, setLoad] = useState(false);
  //data
  const [applications, setApplications] = useState([]);
  const [canSeeForm, setCanSeeForm] = useState(false);
  const [visibility, setVisibility] = useState(false);
  //funcionalidad
  const autentication = (url) => {
    window.location = url;
  };
  //useEffect
  useEffect(() => {
    const { api, setApiToken, getApiToken } = config();
    const getApplications = async () => {
      await Axios.get(api + 'access/permission').then(({ data }) => {
        if (data.response === false) {
          if (params.token) {
            setApiToken(params.token);
            Axios.defaults.headers['Authorization'] = getApiToken();
            navigate('/core');
          }
        } else {
          if (params.token) {
            setApiToken(params.token);
            navigate('/core');
          }
          let idsApplications = [];
          for (const application of data) {
            if (idsApplications.indexOf(application.id_application) === -1) {
              idsApplications.push(application.id_application);
            }
          }
          showApplications(idsApplications);
        }
      });
    };
    const showApplications = async (idsApplications) => {
      await Axios.get(api + 'application/all').then(({ data }) => {
        let apps = [];
        data.forEach((item) => {
          if (
            idsApplications.indexOf(item.id_application) !== -1 &&
            item.id_application !== 1
          ) {
            apps.push(item);
          }
        });
        setApplications(apps);
        setLoad(true);
      });
    };

    const checkIfCanSeeFormLink = async () => {
      Axios.get(api + 'access/user_type')
        .then(({ data }) => {
          const userType = data.find((item) => item.id_application == 5);
          if (userType) {
            const { user_type } = userType;
            setCanSeeForm(
              user_type == 'Docente académico' || user_type == 'Docente técnico'
            );
          }
        })
        .catch((error) => {
          console.error(error);
        });
    };

    checkIfCanSeeFormLink();

    getApplications();
  }, [params, navigate]);

  const toggleVisibility = () => setVisibility((prevValue) => !prevValue);

  /**
    que cuando le de click a ingresar a una aplicacion en la url vaya el parametro del api token
    cuando ya se tenga en la aplicacion de destino, se obtiene, se guarda, se obtienen los datos
    del usuario como tal y, se le da un mensaje de bienvenida y cuando se cierre este dialogo
    se le quita el api token de la url
   */
  //render
  return (
    <Box className={classes.root}>
      <Grid container spacing={3}>
        <AppsIcon style={{ fontSize: 40 }} />
        <Typography variant="h4" style={{ display: 'inline-block' }}>
          Aplicaciones
        </Typography>
      </Grid>
      {canSeeForm && (
        <Box
          style={{
            marginBlock: '25px 15px',
          }}
        >
          <Alert variant="filled" severity="info">
            Recuerda que si eres docente o instructor técnico debes llenar el
            formulario de &nbsp;
            <b>
              ACTUALIZACIÓN DE INFORMACIÓN PARA SISTEMA SIGES Y PLANTA DOCENTE
            </b>
            <Button
              variant="contained"
              color="white"
              style={{ marginLeft: '8px' }}
              onClick={toggleVisibility}
            >
              Llenar formulario
            </Button>
          </Alert>
          <FormDialog
            visibility={visibility}
            toggleVisibility={toggleVisibility}
          />
        </Box>
      )}
      <Grid container spacing={3}>
        {applications.length === 0 && load && (
          <Grid item xs={12}>
            <Typography variant="body2">
              No se encontro acceso a ninguna aplicacion
            </Typography>
          </Grid>
        )}
        {load &&
          applications.map((application) => {
            return (
              <Grid
                key={application.id_application}
                item
                xs={12}
                sm={6}
                md={4}
                lg={3}
              >
                <CardApplication
                  title={application.application}
                  description={application.description}
                  image={images + 'application/' + application.logo}
                  titleImage={application.application}
                  auth={autentication}
                  url={application.url + '/' + getApiToken()}
                />
              </Grid>
            );
          })}
        {/* Backdrop */}
        <BackgroundLoad open={!load} />
      </Grid>
    </Box>
  );
};
export default HomeContainer;
