import React from 'react';
import {
  Box,
  Chip,
  Container,
  Grid,
  TextField,
  Button,
} from '@material-ui/core';
import Save from '@material-ui/icons/Save';
import style from './style';

const Step2 = ({
  permission,
  permissions,
  changeField,
  clickAdd,
  clickDelete,
}) => {
  const classes = style();
  //render
  return (
    <Box py={2}>
      <Container maxWidth="md">
        <Grid container spacing={3}>
          <Grid item xs={12} md={9}>
            <TextField
              fullWidth
              label="Permiso en la aplicación"
              name="permission"
              required
              variant="outlined"
              value={permission.permission}
              onChange={changeField}
              autoComplete="off"
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <Button
              fullWidth
              className={classes.buttonInline}
              color="primary"
              variant="contained"
              startIcon={<Save />}
              onClick={clickAdd}
            >
              Agregar Permiso
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Box component="ul" className={classes.root}>
              {permissions.map((permission) => {
                return (
                  <li key={permission.id_permission}>
                    <Chip
                      label={permission.permission}
                      onDelete={() => {
                        clickDelete(permission.id_permission);
                      }}
                      className={classes.chip}
                    />
                  </li>
                );
              })}
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Step2;
