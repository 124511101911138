import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Axios from 'axios';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Divider,
  Grid,
  TextField,
} from '@material-ui/core';
import Save from '@material-ui/icons/Save';
import BackgroundLoad from '../../components/BackgroundLoad';
import ConfirmDialog from '../../components/ConfirmDialog';
import Toast from '../../components/Toast';
import config from '../../config';
import style from './style';

const ChangePasswordContainer = () => {
  const classes = style();
  let navigate = useNavigate();
  //const
  const { api, getApiToken, toFormData } = config();
  Axios.defaults.headers['Authorization'] = getApiToken();
  const [toast, setToast] = useState({ isOpen: false, message: '', type: '' });
  const [loading, setLoading] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState({ isOpen: false });
  //data
  const [reset, setReset] = useState({
    old_password: '',
    new_password: '',
    new_password_two: '',
  });
  //metodos
  const changeField = (e) => {
    setReset({ ...reset, [e.target.name]: e.target.value });
  };
  const logout = () => {
    Axios.post(api + 'profile/logout', null).then(() => {
      navigate('/login');
    });
  };
  //peticiones
  const changePassword = () => {
    setLoading(true);
    Axios.post(api + 'profile/update_password', toFormData(reset))
      .then(({ data }) => {
        setToast({
          isOpen: true,
          message: data.message,
          type: data.response ? 'success' : 'warning',
        });
        if (data.response) {
          setConfirmDialog({
            isOpen: true,
            title: 'Información',
            message: 'Al cambiar la contraseña debe iniciar sesión nuevamente',
            clickCancel: logout,
            clickConfirm: logout,
          });
        }
      })
      .then(() => setLoading(false));
  };
  return (
    <>
      <Box className={classes.root}>
        <Card>
          <CardHeader
            title="Cambiar contraseña"
            titleTypographyProps={{ variant: 'h6' }}
          />
          <Divider />
          <CardContent>
            <Container maxWidth="sm">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    type="password"
                    fullWidth
                    helperText="Ingrese su contraseña actual"
                    label="Contraseña actual"
                    name="old_password"
                    required
                    variant="outlined"
                    value={reset.old_password}
                    onChange={changeField}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    type="password"
                    fullWidth
                    helperText="Ingrese la nueva contraseña"
                    label="Nueva contraseña"
                    name="new_password"
                    required
                    variant="outlined"
                    value={reset.new_password}
                    onChange={changeField}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    type="password"
                    fullWidth
                    helperText="Repita la nueva contraseña"
                    label="Repita Nueva contraseña"
                    name="new_password_two"
                    required
                    variant="outlined"
                    value={reset.new_password_two}
                    onChange={changeField}
                  />
                </Grid>
              </Grid>
            </Container>
          </CardContent>
          <Divider />
          <Box display="flex" justifyContent="flex-end" p={2}>
            <Button
              color="primary"
              variant="contained"
              startIcon={<Save />}
              onClick={changePassword}
            >
              Guardar cambios
            </Button>
          </Box>
        </Card>
      </Box>
      {/* ConfirmDialog */}
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
      {/* Backdrop */}
      <BackgroundLoad open={loading} />
      {/* alert */}
      <Toast toast={toast} setToast={setToast} />
    </>
  );
};

export default ChangePasswordContainer;
