import React from 'react';
import { Link, Typography } from '@material-ui/core';

const Copyright = () => {
  return (
    <>
      <Typography
        variant="body2"
        color="textSecondary"
        align="center"
        noWrap={false}
      >
        Copyright &copy; Desarrollado por el Departamento de
        <br />
        Desarrollo de Software del
        <Link href="https://www.ricaldone.edu.sv/" target="_blank">
          {' '}
          Instituto Técnico Ricaldone
        </Link>
      </Typography>
    </>
  );
};

export default Copyright;
