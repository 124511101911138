import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  AppBar,
  Box,
  Button,
  Container,
  Divider,
  Menu,
  MenuItem,
  Tab,
  Tabs,
  Typography,
} from '@material-ui/core';
import AppsIcon from '@material-ui/icons/Apps';
import Settings from '@material-ui/icons/Settings';
import AddApplication from './AddApplication';
import TabPanel from '../../components/TabPanel';
import ApplicationList from './ApplicationList';
import config from '../../config';
import style from './style';

const ApplicationContainer = (props) => {
  const classes = style();
  //const
  const { actionsOnly, getActionsOnly, actionsPermissions } = props;
  const { searchArr } = config();

  //dropdown
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  //tabs
  const [value, setValue] = useState(0);
  const changeTab = (event, newValue) => {
    setValue(newValue);
  };
  function a11yProps(index) {
    return {
      id: `nav-tab-${index}`,
      'aria-controls': `nav-tabpanel-${index}`,
    };
  }
  //useEffect
  useEffect(() => {
    getActionsOnly({
      actions: actionsPermissions,
      permission: 'Aplicaciones',
    });
  }, [getActionsOnly, actionsPermissions]);
  //render
  return (
    <Container maxWidth={false} className={classes.root}>
      <Box
        alignItems="center"
        width="100%"
        display="flex"
        justifyContent="space-between"
        mb={2}
      >
        <Box flexGrow="1">
          <Box display="flex" alignItems="center">
            <AppsIcon style={{ fontSize: 40 }} />
            <Typography variant="h4" style={{ display: 'inline-block' }}>
              Aplicaciones
            </Typography>
          </Box>
        </Box>
        <Box display={searchArr(actionsOnly, 'Acciones') ? '' : 'none'}>
          <Button
            aria-controls="settings-menu"
            aria-haspopup="true"
            onClick={handleClick}
            startIcon={<Settings />}
          >
            Opciones
          </Button>
          <Menu
            id="settings-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem component={RouterLink} to="/core/applications/actions">
              Gestionar acciones
            </MenuItem>
          </Menu>
        </Box>
      </Box>
      <Divider />

      <Box
        className={classes.tabs}
        display={searchArr(actionsOnly, 'Ver') ? '' : 'none'}
      >
        <AppBar position="static">
          <Tabs
            value={value}
            onChange={changeTab}
            indicatorColor="secondary"
            variant="fullWidth"
            aria-label="Tabs Aplicaciones"
          >
            <Tab label="Lista de Aplicaciones" {...a11yProps(0)} />
            <Tab label="Agregar Aplicación" {...a11yProps(1)} />
          </Tabs>
        </AppBar>

        <TabPanel value={value} index={0}>
          <ApplicationList />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <AddApplication />
        </TabPanel>
      </Box>
    </Container>
  );
};
const mapDispatchToProps = (dispatch) => ({
  getActionsOnly(data) {
    dispatch({
      type: 'SET_ACTIONS_ONLY',
      data,
    });
  },
});

const mapStateToProps = (state) => {
  return state;
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ApplicationContainer);
