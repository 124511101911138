import React from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';

import style from './style';

const SidebarItem = ({ href, icon: Icon, title }) => {
  const classes = style();

  return (
    <ListItem
      button
      activeClassName={classes.active}
      component={RouterLink}
      to={href}
    >
      <ListItemIcon>
        {Icon && <Icon className={classes.icon} size="20" />}
      </ListItemIcon>
      <ListItemText primary={title} />
    </ListItem>
  );
};

SidebarItem.propTypes = {
  href: PropTypes.string,
  icon: PropTypes.elementType,
  title: PropTypes.string,
};

export default SidebarItem;
