import React, { useState, useEffect } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import Axios from 'axios';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Link,
  TextField,
  MenuItem,
} from '@material-ui/core';
import { ArrowBack, Save } from '@material-ui/icons';
import BackgroundLoad from '../../components/BackgroundLoad';
import Toast from '../../components/Toast';
import config from '../../config';

const UserType = () => {
  const params = useParams();
  const id_user = params.id_user;
  //const
  const { api, getApiToken } = config();
  Axios.defaults.headers['Authorization'] = getApiToken();
  const [toast, setToast] = useState({ isOpen: false, message: '', type: '' });
  const [loading, setLoading] = useState(false);
  //data
  const [user, setUser] = useState({
    id_user: params.id_user,
    first_name: '',
    last_name: '',
    charge: 0,
    user_types: [],
  });
  const [applications, setApplications] = useState([]);
  const [userTypes, setUserTypes] = useState([]);
  const [idsUserTypes, setIdsUserTypes] = useState([]);
  //metodos
  const changeField = (e, index) => {
    if (e.target.value !== '') {
      idsUserTypes[index] = e.target.value;
      let values = Object.values(idsUserTypes);
      let realValues = values.filter((value) => value > 0);
      setUser({ ...user, user_types: realValues });
    } else {
      idsUserTypes[index] = 0;
    }
  };
  //peticiones
  const update = () => {
    setLoading(true);
    Axios.post(api + 'user_user_type/' + user.id_user, user)
      .then(({ data }) => {
        setToast({
          isOpen: true,
          message: data.message,
          type: data.response ? 'success' : 'warning',
        });
      })
      .then(() => setLoading(false));
  };
  //useEffect
  useEffect(() => {
    const { api } = config();
    const getApplications = async (preData) => {
      await Axios.get(api + 'application/all').then(({ data }) => {
        setApplications(data);
        let selects = {};
        data.forEach((application) => {
          let id_app = application.id_application;
          let search = preData.find(
            (preItem) => preItem.id_application === id_app
          );
          selects[id_app] = search ? search.id_user_type : 0;
        });
        setIdsUserTypes(selects);
      });
    };
    const getUserTypes = async () => {
      await Axios.get(api + 'action_permission_user_type/all').then(
        ({ data }) => {
          setUserTypes(data);
        }
      );
    };
    const getUser = async () => {
      await Axios.get(api + 'user/one/' + id_user).then(({ data }) => {
        setUser({
          id_user: data.id_user,
          first_name: data.first_name,
          last_name: data.last_name,
          charge: data.charge,
          user_types: [],
        });
      });
    };
    const getUserUserTypes = async () => {
      await Axios.get(api + 'user_user_type/' + id_user).then(({ data }) => {
        getApplications(data);
      });
    };
    getUserTypes();
    getUser();
    getUserUserTypes();
  }, [id_user]);
  //render
  return (
    <>
      <Box mb={2}>
        <Link component={RouterLink} to="/core/users" underline="none">
          <Button variant="text" startIcon={<ArrowBack />}>
            Regresar
          </Button>
        </Link>
      </Box>
      <Card>
        <CardHeader
          title="Tipos de usuario por aplicación"
          titleTypographyProps={{ variant: 'h6' }}
          subheader={
            user.first_name + ' ' + user.last_name + ' - ' + user.charge
          }
        />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            {applications.map((application) => {
              return (
                <Grid item xs={12} md={3} key={application.id_application}>
                  <TextField
                    id="outlined-select-currency"
                    select
                    label={application.application}
                    fullWidth
                    value={
                      idsUserTypes[application.id_application]
                        ? idsUserTypes[application.id_application]
                        : ''
                    }
                    onChange={(event) => {
                      changeField(event, application.id_application);
                    }}
                    variant="outlined"
                  >
                    <MenuItem value={0}>Seleccionar...</MenuItem>
                    {userTypes.map((userType) => {
                      if (
                        userType.id_application === application.id_application
                      ) {
                        return (
                          <MenuItem
                            key={userType.id_user_type}
                            value={userType.id_user_type}
                          >
                            {userType.user_type}
                          </MenuItem>
                        );
                      } else {
                        return null;
                      }
                    })}
                  </TextField>
                </Grid>
              );
            })}
          </Grid>
        </CardContent>
        <Divider />
        <Box display="flex" justifyContent="flex-end" p={2}>
          <Button
            color="primary"
            variant="contained"
            startIcon={<Save />}
            onClick={update}
          >
            Guardar Cambios
          </Button>
        </Box>
      </Card>
      {/* Backdrop */}
      <BackgroundLoad open={loading} />
      {/* alert */}
      <Toast toast={toast} setToast={setToast} />
    </>
  );
};

export default UserType;
