import { makeStyles } from '@material-ui/core/styles';
import { orange, red } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
  tabs: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    marginTop: 5,
  },
  waring: {
    color: orange[500],
  },
  danger: {
    color: red[500],
  },
}));

export default useStyles;
